import router from "../router"
import i18n from "../i18n"
import userGroups from "../userGoups";

const mutations = {
	publicDocument(state) {
		state.publicDocument = true
	},
	setReceiversOption(state, value) {
		state.receivers.options = value
	},
	setTableSearchValue(state, value) {
		state.tableSearchValue = value
	},
	setExtendedSearch(state, value) {
		state.extendedSearchValue.extendedSearch = value.extendedSearch
		state.extendedSearchValue.legalEntityNumber = value.legalEntityNumber
		state.extendedSearchValue.showExtendedSearch = value.showExtendedSearch

	},
	setExtendedSearchDocType(state, value) {
		state.extendedSearchValue.extendedSearch.documentType = value
	},
	setExtendedSearchErrorChannels(state, value) {
		state.extendedSearchValue.extendedSearch.errorChannels = value
	},
	setExtendedSearchIncludeHandled(state, value) {
		if (!state.extendedSearchValue.extendedSearch.includeHandled) {
			state.extendedSearchValue.extendedSearch.includeHandled = false
		}
		state.extendedSearchValue.extendedSearch.includeHandled = value
	},
	setShowExtendedSearch(state, value) {
		state.extendedSearchValue.showExtendedSearch = value
	},
	
	setExtendedSearchLe(state, value) {
		state.extendedSearchValue.legalEntityNumber = value
	},
	setExtendedSearchValues(state, value) {
		state.extendedSearchValue.extendedSearch = value.extendedSearch
	},

	overviewTableDataRefreshed(state, value) {
		state.overviewTableDataRefreshed = value
	},

	setOverviewPageNumber(state, value) {
		state.overviewPageNumber = value
	},
	overviewTableSort(state, value) {
		state.overviewTableSort = value
	},
	displayViewUser(state, value) {
		state.userManagement.displayViewUser = value
	},
	displayDeleteUser(state, value) {
		state.userManagement.displayDeleteUser = value
	},
	displayResendDocument(state, value) {
		state.documentDetail.displayResendDocument = value
	},
	resendAfterAddAttach(state, value) {
		state.resendAfterAddAttachment = value
	},

	displayAddAttachment(state, value) {
		state.displayAddAttachment = value
	},
	displayAskPopUp(state, payload) {
		state.askPopUpAction = payload.action
		state.displayAskPopUp = payload.display
	},
	displayFindUser(state, value) {
		state.userManagement.displayFindUser = value
	},
	displayInviteUser(state, value) {
		state.userManagement.displayInviteUser = value
	},
	displayEditUser(state, value) {
		state.userManagement.displayEditUser = value
	},
	displayEditReceiver(state, value) {
		state.receiverManagement.displayEditReceiver = value
	},
	displayEditReceiverViewMode(state, value) {
		state.receiverManagement.viewMode = value
	},
	displayDeleteReceiver(state, value) {
		state.receiverManagement.displayDeleteReceiver = value
	},
	displayCancelMandate(state, value) {
		state.mandates.displayCancelMandate = value
	},
	displayEditMandate(state, value) {
		state.mandates.displayEditMandate = value
	},
	displayBankAccount(state, value) {
		state.mandates.displayBankAccount = value
	},
	displayManageUser(state, value) {
		state.userManagement.displayManageUser = value
	},
	displayResetPassword(state, value) {
		state.userManagement.displayResetPassword = value
	},

	resetLoggedInUserPassword(state, payload) {
		state.resetLoggedInUserPassword = payload
	},
	clearErrors(state) {
		state.errorNotification.display = false
		state.errorNotification.type = ''
	},
	displayErrorNotification(state, payload) {
		state.errorNotification.display = payload
	},
	setErrorNotificationType(state, payload) {
		state.errorNotification.type = payload
	},

	displayNotification(state, payload) {
		state.generalNotification.display = payload
		state.errorNotification.type = ''
	},

	setDisplayExtraInfo(state, payload) {
		state.extraInfo.display = payload
	},
	setDeleteMandateText(state, payload) {
		state.generalNotification.deleteMandateText = payload
	},
	setGeneralNotificationText(state, payload) {
		state.generalNotification[payload.field] = payload.text
	},
	setCookiesWarningText(state, payload) {
		state.generalNotification.cookiesWarningText = payload
	},

	setNotificationText(state, payload) {
		state.generalNotification.text = payload
	},

	updateSGReportConfig(state, payload) {
		state.supplierGroup.data.reportingConfig = payload
	},
	failedTransactionUpdated(state, payload) {
		state.failedTransactionUpdated = payload
	},
	updateSupplierLegalEntities(state, payload) {
		state.supplierGroup.data.supplierLegalEntities[payload.entityNumber].legalEntityConfig.reportingConfig = payload.legalEntityConfig.reportingConfig
	},
	setDisplayHandleError(state, payload) {
		state.displayHandleError = payload
	},
	setOnboardingSuccssefulText(state, payload) {
		state.onboarding.successfulTextField = payload
	},
	setUserData(state, data) {
		state.userManagement.userData = data
	},
	setReceiverData(state, data) {
		state.receiverManagement.receiverData = data
	},
	setMandateData(state, data) {
		state.mandates.mandateData = data
	},
	setTableSearchValueManagement(state, value) {
		state.tableSearchValueManagement = value
	},
	setTableSearchValueReceivers(state, value) {
		state.tableSearchValueReceivers = value
	},
	setDataManagmentExtendedSearch(state, value) {
		state.dataManagementExtendedSearch = value
	},
	displayLoadingNotification(state, value) {
		state.loadingNotification.display = value.display
		state.loadingNotification.type = value.type
	},
	setTablePage(state, payload) {
		state.tableInitialPage[payload.field] = payload.pageNo
	},
	setMandateTableSearch(state, payload) {
		state.tableSearchValueMandates = payload
	},
	setPostDropTableSearch(state, payload) {
		state.postDropTableSearchValue = payload
	},
	setActivePaymentTab(state, payload) {
		state.paymentActiveTab = payload
	},
	setPasswordResetDisplay(state, payload) {
		state.passwordResetSuccessDisplay = payload
	},

	setDisplayResetOwnPassword(state, payload) {
		state.displayResetOwnPassword = payload
	},
	setPageYScrollPosition(state, payload) {
		state.pageYScrollPosition = payload
	},

	setTableSorters(state, payload) {
		if (payload.order) {
			state.tableSorters[0].place = payload.place !== undefined ? payload.place : ''
			state.tableSorters[0].order = payload.order
			state.tableSorters[0].sortField = payload.sortField
		} else {
			state.tableSorters = [{}]
		}
	},
	setTablePostdropSorters(state, payload) {
		if (payload.order) {
			state.tablePostdropSorters[0].order = payload.order
			state.tablePostdropSorters[0].sortField = payload.sortField
		} else {
			state.tablePostdropSorters = [{}]
		}
	},
	setResendBatch(state, payload) {
		state.resendBatch = payload
	},

	setManagementActiveTab(state, payload) {
		state.managementActiveTab = payload
	},
	setLocale(state, lang) {
		state.locale = lang
	},
	documentDeleted(state, payload) {
		state.documentDeleted = payload
	},

	setMandateCanceled(state, payload) {
		state.mandateCanceled = payload
	},
	setOverviewTableRefreshed(state, payload) {
		state.overviewTableRefreshed = payload
	},

	setUserReceivers(state, receivers) {
		let tempReceivers = Object.values(Object.assign({}, receivers))
		tempReceivers.forEach((rec) => {
			let receiverName = ''
			if (rec.info.firstName && rec.info.lastName) {
				receiverName = rec.info.firstName + ' ' + rec.info.lastName + ' - '
			}

			rec.info.receiverName = receiverName
			state.userManagement.userReceivers.push(rec)
		})
	},
	setUserAuth(state, value) {
		state.userIsAuthenticated = value
	},
	setToken(state, value) {
		state.token = value
	},

	setErrors(state, payload) {
		state.errors[payload.field] = payload.value
	},
	setRefreshToken(state, value) {
		state.refreshToken = value
	},
	setProxyToken(state, value) {
		state.proxyToken = value
	},
	setTokenIsChecking(state, value) {
		state.tokenIsChecking = value
	},
	setLegalEntitiesData(state, data) {
		state.legalEntitiesData.data = data
		state.legalEntitiesData.isReady = true
	},
	setDocumentDetailData(state, data) {
		state.documentDetailData = data
	},

	setDocumentDetailOpTableInfo(state, data) {
		state.documentDetailData.opTableInfo = data
	},

	setSupplierGroupData(state, data) {
		state.supplierGroup.data = data
	},
	setSupplierGroupBranding(state, brandingSettings) {
		state.supplierGroup.branding = brandingSettings
	},
	setCurrentUserData(state, payload) {
		state.currentUserData.data = payload.currentUserData
		state.currentUserData.isReady = payload.isReady
	},
	receiverDataUpdated(state, payload) {
		state.receiverDataUpdated = payload
	},
	setCreateReceiverId(state, payload) {
		state.receiverManagement.createReceiverId = payload
	},
	channelChoiceDataSaved(state, payload) {
		state.onboarding.channelChoice.dataSaved = payload
	},
	setDisplayAddReceiver(state, payload) {
		state.receiverManagement.displayAddReceiver = payload
	},

	setDocumentsInfo(state, documentsInfo) {
		state.documentsInfo.data = documentsInfo.data
		state.documentsInfo.pagination.currentPage = documentsInfo.pagination.currentPage
		state.documentsInfo.pagination.totalPages = documentsInfo.pagination.totalPages
	},

	setPublicDocumentsInfo(state, publicDocumentInfo) {
		state.publicDocumentInfo.data = publicDocumentInfo
	},
	setOnboardindDocumentData(state, onboardingData) {
		state.onboarding.data = onboardingData.items[0]

	},
	accessCodeReplyLength(state, payload) {
		state.onboarding.accessCodeReplyLength = payload
	},
	setOnboardingChoice(state, choice) {
		state.onboarding.data.mandateOnboardingInfo.choice = choice
	},
	displayChannelChoice(state, payload) {
		state.onboarding.channelChoice.display = payload
	},
	setChannelChoiceData(state, payload) {
		state.onboarding.channelChoice.data = payload
	},
	setUserOnboardingMandates(state, mandatesData) {
		state.onboarding.data.mandates = mandatesData
		state.onboarding.isReady = true
	},

	setOnboardingSuccessfulData(state, successData) {
		state.onboarding.successfulData = successData
	},
	setTempFile(state, data) {
		state.tempFile = data
	},

	emptyTempFile(state, data) {
		state.tempFile = data
	},

//general portals
	setOverviewTableColumns(state, data) {
		if (data === 'BILLING') {
			state.columnsOverview = []
			state.columnsDocDetail = []
			if (userGroups.receiverNames.includes(state.currentUserData.data.group) || state.customizedColumns.length === 0) {
				let columns = []
				Object.entries(state.columnsOverviewBilling).forEach(col => columns.push(col[1]))
				columns.find(col => col.field === 'viewProperties').width = '3%'
				columns.find(col => col.field === 'customerName').width = '20%'
				state.columnsOverview = columns
				state.columnsDocDetail = columns
			} else {
				let columns = new Array(state.customizedColumns.length - 1)
				state.customizedColumns.forEach(column => {
					if (state.columnsOverviewBilling[column.columnId] !== undefined) {
						let col = state.columnsOverviewBilling[column.columnId]
						col.width = column.width + '%'
						columns[column.index - 1] = col
					} else if (state.extraBillingColumns[column.columnId + 'Column'] !== undefined) {
						let col = state.extraBillingColumns[column.columnId + 'Column']
						col.width = column.width + '%'
						columns[column.index - 1] = col
					} else if (column.columnId === "visibility") columns.pop()
				})
				state.columnsOverview = columns
				state.columnsDocDetail = columns
			}
		}
		if (data === 'SALARY') {
			state.columnsOverview = state.columnsOverviewSalary
		}
		if (data === 'GENERAL') {
			state.columnsOverview = state.columnsOverviewGeneral
		}
	},

	setDocDetailColumns(state, data) {
		if (data === 'SALARY') {
			state.columnsDocDetail = state.columnsDocDetailSalary
		}
		if (data === 'GENERAL') {
			state.columnsDocDetail = state.columnsDocDetailGeneral
		}
	},
	setDocType(state, data) {
		state.docType = data
	},

	setEmailSuppressions(state, data) {
		state.emailSuppression.data = data
	},
	setDocumentFileSizes(state, data) {
		state.documentFileSizes.pdfSize = data.pdfSize
		state.documentFileSizes.combinedSize = data.combinedSize
	},

	setEmailSuppressionsCheck(state, payload) {
		state.emailSuppression.check[payload.errorType].push({email: payload.email, reason: payload.reason})
	},
	setEmailSuppressionsStatus(state, data) {
		state.emailSuppression.isReady = data
	},
	setEmailSuppressionsSearch(state, data) {
		state.emailSuppression.search = data
	},
	emailReactivated(state, data) {
		state.emailSuppression.emailReactivated = data
	},

	redirectPageAfterLogin(state, path) {
		state.redirectPageAfterLogin = path
	},
	fromToRoute(state, payload) {
		state.route.from = payload.from
		state.route.to = payload.to
	},

	fromResetPassword(state, payload) {
		state.fromResetPassword = payload
	},

	setMandateExists(state, payload) {
		state.mandateExists = payload
	},
	setCreateDepartment(state, payload) {
		state.postDrop.displayCreateDepartment = payload
	},
	departmentsUpdated(state, payload) {
		state.postDrop.departmentsUpdated = payload
	},
	setDepartmentData(state, payload) {
		state.postDrop.departmentData = payload
	},
	displayEditDepartment(state, payload) {
		state.postDrop.displayEditDepartment = payload
	},
	displayDeleteDepartment(state, payload) {
		state.postDrop.displayDeleteDepartment = payload
	},
	displayDepartmentUploadFiles(state, payload) {
		state.postDrop.displayDepartmentUploadFiles = payload
	},
	displayAddDepartmentUser(state, payload) {
		state.postDrop.displayAddDepartmentUser = payload
	},
	displayDeleteDepartmentUser(state, payload) {
		state.postDrop.displayDeleteDepartmentUser = payload
	},
	displayViewDepartmentUser(state, payload) {
		state.postDrop.displayViewDepartmentUser = payload
	},
	//DELETE LATER
	setUsersDataForPostDrop(state, payload) {
		state.postDrop.usersData = payload
	},

	setSupplierUsers(state, payload) {
		let userList = []
		payload.forEach(user => {
			let tempItem = {label: user.email, value: user.id}
			userList.push(tempItem)
		})

		if (!userList.length) userList.push({label: "No supplier user", value: ''})
		state.supplierUsersList = userList
	},
	setDisplayUploadFileLoader(state, payload) {
		state.displayUploadFileLoader = payload
	},
	setDisplayConfirmation(state, payload) {
		state.displayConfirmation = payload
	},

	setUserDepartmentCodeList(state, payload) {
		let list = []
		payload.forEach(item => {
			let tempItem = {
				value: item.code,
				label: item.departmentName
			}
			list.push(tempItem)
		})

		if (list.length === 0) {
			list.push({label: "No departments", value: ''})
		}

		state.userDepartmentCodeList = list
		state.departmentsSet = true
	},

	setUploadedBatches(state, value) {
		state.uploadedBathes = value
	},
	setUploadBatchId(state, value) {
		state.postDrop.uploadBatchId = value
	},
	setPrintBatchAfterUpload(state, value) {
		state.postDrop.printBatchAfterUpload = value
	},
	setSuccessUploadedFilesTotal(state) {
		state.successfulUploadedFilesTotal = state.successfulUploadedFilesTotal + 2
	},
	setErrorUploadedFilesTotal(state) {
		state.successfulUploadedFilesTotal = state.errorUploadedFilesTotal + 2
	},
	clearUploadedFiles(state) {
		state.successfulUploadedFilesTotal = 0
		state.successfulUploadedFilesTotal = 0
	},
	displayValidate(state, value) {
		state.postDrop.displayValidate = value
	},

	setVatIsOnPeppol(state, value) {
		state.vatCheck = value
	},
	setPeppolIsChecked(state, value) {
		state.peppolIsChecked = value
	},
	setUploadedFileNamesError(state, name) {
		state.uploadedFileNamesError.push(name)
	},

	setErrorUploadedFiles(state, files) {
		state.errorUploadedFiles.push(files)
	},
	addUploadedFile(state, name) {
		state.uploadedFileNames.push(name)
	},

	setUploadFileErrorOccurred(state, value) {
		state.uploadedFileErrorOccurred = value
	},
	setResendErrorFiles(state, value) {
		state.resendErrorFiles = value
	},
	setResetErrorUploadedFiles(state, value) {

		state.resendErrorUploadedFiles = value
	},
	setDisplayResendErrorFilesButton(state, value) {

		state.displayResendErrorFilesButton = value
	},
	ibanIsChecked(state, payload) {
		state.iban.isChecked = payload.isChecked
		state.iban.data = payload.ibanData
	},
	setIbanIsChecked(state, payload) {
		state.ibanIsChecked = payload
	},

	setProcessedDocumentData(state, data) {
		state.processedDocumentData = data
		let docId = data.docId
		let link = '/postDrop/' + docId
		router.push(link)
	},
	setPostDropExtendedSearch(state, value) {
		state.postDrop.extendedSearch = value
	},
	setPostDropUploadedFilesExtendedSearch(state, value) {
		state.postDrop.uploadedFiles = value
	},

	setPendingBatchesData(state, payload) {
		let idArrays = []
		payload.forEach(item => {
			if (item.batch) idArrays.push(item.batch)
		})
		let uniqueIdArrays = [...new Set(idArrays)]

		let dropDownOptions = [{label: i18n.t('dropdowns.postDrop.batch'), value: ''}]
		uniqueIdArrays.forEach(item => dropDownOptions.push({label: item, value: item}))
		state.postDrop.pendingBatches.ids = dropDownOptions
		state.postDrop.pendingBatches.isReady = true
	},
	setPendingBatchesIsReady(state, value) {
		state.postDrop.pendingBatches.isReady = value
	},
	setPendingTableDataIsReady(state, value) {
		state.postDrop.pendingTable.isReady = value

	},
	setActiveComponent(state, value) {
		state.activeComponent = value
	},

	setPendingTableData(state, data) {
		let allBatchIds = []
		let noBatchId = false
		let fileNumbers = 0
		let tableData = []

		data.forEach(item => {
			if (item.batch) allBatchIds.push(item.batch)
			else noBatchId = true
		})
		let uniqueBatches = [...new Set(allBatchIds)]
		state.postDrop.pendingTable.allBatchIds = uniqueBatches

		if (noBatchId) {
			uniqueBatches.unshift("-")
		}

		uniqueBatches.forEach(id => {
			tableData.push(
				{
					batchId: id,
					fileName: []
				}
			)
		})

		data.forEach(item => {
			let batchRow = {}
			if (item.batch) {
				batchRow = tableData.find(tableRow => tableRow.batchId === item.batch)
				batchRow.startPrintTooltip = i18n.t('tooltips.startPrint')
				batchRow.deleteBatchTooltip = i18n.t('tooltips.deleteBatch')
				batchRow.department = item.department
				batchRow.postalType = item.postalType
				batchRow.printMode = item.printMode
				batchRow.fileName.push(item.fileName)
			} else {
				batchRow = tableData.find(tableRow => tableRow.batchId === '-')
				batchRow.disabledStartPrintTooltip = i18n.t('tooltips.disabledStartPrint')
				batchRow.disabledDeleteBatchTooltip = i18n.t('tooltips.disabledDeleteBatch')
				let fields = ["department", "postalType", "printMode"]
				fields.forEach(field => {
					if (!batchRow[field]) batchRow[field] = []
					if (!batchRow[field].includes(item[field])) batchRow[field].push(item[field])
				})
				batchRow.fileName.push(item.fileName)
			}
			batchRow.viewFilesTooltip = i18n.t('tooltips.viewFiles')
		})

		state.postDrop.pendingTable.data = tableData
		state.postDrop.pendingTable.filesNumber = fileNumbers
		state.postDrop.pendingTable.isReady = true
	},
	setTotalFilesNumber(state, number) {
		state.totalFilesNumber = number
	},
	documentDetailErrorHandled(state) {
		state.documentDetailData.channels.archive.errorsHandled = true
	},
	processedDocumentErrorHandled(state) {
		state.processedDocumentData.documentData.errorsHandled = true
	},
	processedDocumentHasError(state) {
		state.processedDocumentData.documentData.processedDocumentHasError = true
	},
	setDocumentErrorHandled(state, value) {
		state.documentErrorHandled = value
	},

	setPrintMode(state, value) {
		state.postDrop.uploadFilePrintMode = value
	},
	setBatchId(state, value) {
		state.postDrop.uploadBatchId = value
	},
	displayLoader(state, value) {
		state.displayLoader = value
	},
	setDepartmentsAreReady(state, value) {
		state.departmentsAreReady = value
	},
	setUblTableSearch(state, value) {
		state.ublTableSearch = value
	},
	setUblDocumentData(state, data) {
		state.ublDocumentData = data
	},
	setViewUblDetail(state, value) {
		state.viewUblDetail = value
	},
	setValidatePopUp(state, value) {
		state.validatePopUp = value
	},
	setValidationResponse(state, data) {
		state.validationResponse = data
	},

	clearUblDocumentData(state) {
		state.ublDocumentData = {}
		state.ublDocumentDataIsReady = false
	},
	manualExist(state, payload) {
		state.manualExist = payload
	},

	setViewFilesData(state, payload) {
		state.postDrop.pendingTable.viewFilesData = payload
	},
	setDeleteBatchData(state, payload) {
		state.postDrop.pendingTable.deleteBatchData = payload
	},

	displayViewFiles(state, payload) {
		state.postDrop.pendingTable.displayViewFiles = payload
	},

	displayDeleteBatch(state, payload) {
		state.postDrop.pendingTable.displayDeleteBatch = payload
	},

	setCheckTokenIMax(state, payload) {
		state.checkTokenIMax = payload
	},

	setCheckTokenICurrent(state, payload) {
		state.checkTokenICurrent = payload
	},
}
export default mutations
