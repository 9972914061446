<template>
	<pop-up-window
		:show="showResendDocument"
		:showClose=false
		:width60=true
		:bottomDiv=true
		:positionAbsolute=true
		:top10=true
		fixed
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('documentDetail.resendDocument.title') }}</h1>
		</template>
		<template #content>
			<div class="user-info divider" v-if="loaded">
				<div class="button-container-outer" >
					<div class="button-container-inner">
						<div v-for="(isActive, type) in resendTypes" :key="type">
							<div
								@mouseenter=displayTooltip(type,true)
								@mouseleave=displayTooltip(type,false)
							>
								<button
									class="btn custom-button text-popup"
									:class="{'inactive' : !isActive}"
									v-if="displayButton(type)"
									@click="makeActive(type)"
									:disabled=isButtonDisabled[type]
								>
									{{ buttonTitle(type) }}
								</button>
							</div>
						</div>
					</div>
					<div class="tooltip-container">
						<div
							class="tooltip edi-tooltip"
							:class="{'display-tooltip' : displayTooltipEdi}"
						>
							{{ ediToolTip }}
						</div>
					</div>
				</div>
				<div class="tooltip-container-attachMail">
					<div
						class="tooltip attachMail-tooltip"
						:class="{'display-tooltip' : displayTooltipAttachMail}"
					>
						{{ ediToolTip }}
					</div>
				</div>
				<div>
					<resend-document-mail
						v-if="displayMail"
						:docId=docId
						:legalEntityId=legalEntityId
						:docsData=docsData
						:mailType=mailType
						:email="email"
						@resend="unselectedDocuments"
						@cancel="cancel"
					>
					</resend-document-mail>

					<resend-document-print
						v-if="displayPrint && !this.$store.state.resendBatch"
						:docId=docId
						:legalEntityId=legalEntityId
						:docsData=docsData
						@close-pop-up="closePopUp"
						@cancel="cancel"
					>
					</resend-document-print>

					<resend-document-edi
						v-if="displayEdi"
						:docId=docId
						:resend-from-main="resendFromMain"
						:docsData=docsData
						@close-pop-up="closeEdiPopUp"
						@cancel="cancel"
						ref="resendEdi"
					>
					</resend-document-edi>

				</div>
			</div>
			<div v-else>
				<p>{{$t('placeholders.loading')}}</p>
			</div>
			<confirmation-with-props
				:description="$t('confirmation.description.confirmAddEditedPeppolIdentifierToCustomerMasterData')"
				:title="'confirmation.title.confirmAddEditedPeppolIdentifierToCustomerMasterData'"
				:show-dialog="showChangeCustomerMasterData"
				@cancel-action="closeBothPopUp"
				@approve-action="changeCustomerMasterData"
			></confirmation-with-props>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"
import ResendDocumentEdi from "../resend/ResendDocumentEdi.vue"
import ResendDocumentPrint from "../resend/ResendDocumentPrint.vue"
import ResendDocumentMail from "../resend/ResendDocumentMail.vue"
import i18n from "../../../i18n"
import ConfirmationWithProps from "../../UI/ConfirmationWithProps.vue";
import userGroups from "../../../userGoups.js";

export default {
	beforeMount() {
		if (this.docId !== undefined && this.$route.name !== 'public' && !userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) this.$store.dispatch('documents/readFileSize', {documentId: this.docId})
	},
	name: "ResendDocument",
	components: {ConfirmationWithProps, ResendDocumentMail, ResendDocumentPrint, ResendDocumentEdi, PopUpWindow},
	emits: ['unselectDocuments', 'closePopUp', 'closePopUpCancel'],
	props: {
		resendFromMain: {
			type: Boolean,
			required: false,
			default: false
		},
		docId: {
			type: Array
		},
		legalEntityId: {
			type: String
		},
		docsData: {
			type: Array
		},
		docsToResend: {
			type: Array
		},
		showResendDocument: Boolean,
		email: {
			type: String,
			required: false
		},
	},
	async mounted() {
		this.ediResendCheck()
		this.setActiveTab()

		let test = {
			attachEmail: true,
			linkEmail: true,
			print: true,
			edi: true
		}

		this.docsToResend.forEach(docToResend => {
			let currentLe
			if (docToResend.data === undefined) currentLe = docToResend.legalEntity.entityNumber
			else currentLe = docToResend.data.legalEntity.entityNumber
			const leSettings = this.$store.state.legalEntitiesData.data.legalEntities[currentLe].channelsConfig
			if (leSettings === undefined) {
				if (this.$store.state.supplierGroup.data.channelsConfig?.attachEmail) this.makeActive('attachMail')
				else if (this.$store.state.supplierGroup.data.channelsConfig?.linkEmail) this.makeActive('linkMail')
				else if (this.$store.state.supplierGroup.data.channelsConfig?.print) this.makeActive('print')
				else if (this.$store.state.supplierGroup.data.channelsConfig?.edi) this.makeActive('edi')
			} else {
				if (!leSettings.attachEmail?.enabled) test.attachEmail = false
				if (!leSettings.linkEmail?.enabled) test.linkEmail = false
				if (!leSettings.print?.enabled) test.print = false
				if (!leSettings.edi?.enabled) test.edi = false
			}

		})

		if (test.attachEmail === true) this.makeActive('attachMail')
		else if (test.linkEmail === true) this.makeActive('linkMail')
		else if (test.print === true) this.makeActive('print')
		else if (test.edi === true) this.makeActive('edi')

		await this.displayDisable()
		this.loaded = true
	},
	data() {
		return {
			currentDocId: this.docId,
			currentLegalEntityId: this.legalEntityId,
			activeType: 'attachMail',
			disabled: true,
			ediCheck: {
				noVAT: false,
			},
			resendTypes: {
				attachMail: true,
				linkMail: false,
				print: false,
				edi: false,
			},
			i18n: i18n,
			displayTooltipEdi: false,
			displayTooltipAttachMail: false,
			showChangeCustomerMasterData: false,
			showNoUbl: false,
			showDifferentIdentifiers : false,
			isButtonDisabled: {
				attachMail: false,
				linkMail: false,
				print: false,
				edi: false
			},
			loaded: false
		}
	},
	computed: {
		ediToolTip() {
			if (this.showNoUbl) {
				return i18n.t('buttons.ediDisabledTooltip.noValidUbl')
			}
			else if (this.showDifferentIdentifiers){
				return i18n.t('buttons.ediDisabledTooltip.differentPeppolIdentifiers')
			}
			else if (this.$store.state.documentFileSizes.pdfSize >= 10) {
				return i18n.t('buttons.disableAtttachMailTooltip')
			}
			return 'No Item to display'
		},

		mailType() {
			let mailType = "ATTACHMENT"
			if (this.activeType === 'linkMail') mailType = "LINK"
			return mailType
		},
		displayEdi() {
			return this.activeType === 'edi'
		},
		displayMail() {
			const pdfSize = this.$store.state.documentFileSizes.pdfSize
			if (this.activeType === "print" || this.activeType === "edi") {
				return false
			}

			if ((this.activeType === "attachMail" && pdfSize >= 10) || this.activeType === "linkMail") {
				if (this.activeType === "linkMail") {
					if (this.$store.state.supplierGroup.data.channelsConfig.linkEmail) {
						this.makeActive("linkMail")
						return true
					} else {
						this.makeActive("attachMail")
						return false
					}
				}
				if (pdfSize >= 10) {
					this.makeActive("linkMail")
					return true
				}
				this.makeActive("attachMail")
				return true
			}
			return true
		},
		displayPrint() {
			return !!(this.activeType === "print"
				&& this.$store.state.supplierGroup.data.channelsConfig?.print?.printJobs
				&& this.$store.state.supplierGroup.data.channelsConfig.print.printProvider === "SPEOS")
		}
	},

	methods: {
		unselectedDocuments() {
			this.$emit("unselectDocuments")
			this.$emit('closePopUp')
		},
		closeBothPopUp(){
			this.showChangeCustomerMasterData = false
			this.$emit('closePopUp')
		},
		closePopUp() {
			this.$emit('closePopUp')
		},
		closeEdiPopUp() {
			if (this.resendFromMain === false && this.$store.state.supplierGroup.data.channelsConfig?.docImage?.channelChoice?.modifyChannel === true && this.$refs.resendEdi.originalPeppolIdentifier !== this.$refs.resendEdi.peppolIdentifier) {
				this.showChangeCustomerMasterData = true
			} else this.$emit('closePopUp')
		},
		cancel() {
			this.$emit("closePopUpCancel")
		},
		async changeCustomerMasterData() {
			const leIds = []
			for (let le in this.$store.state.legalEntitiesData.data.legalEntities) {
				leIds.push(this.$store.state.legalEntitiesData.data.legalEntities[le].entityNumber)
			}
			let documentData = this.$store.getters.getDocumentDetailData
			let clientId = ''
			if (documentData.billingData?.clientId) {
				clientId = documentData.billingData.clientId
			} else if (documentData.opTableInfo) {
				clientId = documentData.opTableInfo.receiver.receiverId
			} else if (documentData.generalDocumentData) {
				clientId = documentData.generalDocumentData.receiverId
			} else if (documentData.salaryData) clientId = documentData.salaryData.employeeId

			let response = await this.$store.dispatch('users/fetchReceiver', {
				formData: {
					receiverId: clientId,
					legalEntityIds: leIds,
					sort: [],
					sortOn: null,
					supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
				}
			})

			let changedData = {
				channelChoice: ["EDI"],
				ediInfo: response.data.data.items[0].channelInfo?.ediInfo === undefined ? {} : response.data.data.items[0].channelInfo.ediInfo,
				holdRelease: response.data.data.items[0].channelInfo?.holdRelease === undefined ? false : response.data.data.items[0].channelInfo.holdRelease,
				legalEntityId: response.data.data.items[0].legalEntityId,
				receiverId: response.data.data.items[0].receiverId,
				supplierGroupId: response.data.data.items[0].supplierGroupId
			}

			changedData.ediInfo.channelProvider = this.$refs.resendEdi.ediProvider.value
			changedData.ediInfo.companyIdentifierNumber = this.$refs.resendEdi.peppolIdentifier
			changedData.ediInfo.companyIdentifierNumberType = 'GENERAL'

			this.$store.dispatch('users/updateReceiver', {
				formData: changedData
			})
			this.showChangeCustomerMasterData = false
			this.$emit('closePopUp')
		},
		setActiveTab() {
			if (this.$store.state.askPopUpAction === "checkOnPeppol") {
				this.resendTypes = {
					attachMail: false,
					linkMail: false,
					print: false,
					edi: true,
				}
				this.activeType = "edi";
			}
		},
		ediResendCheck() {
			if (this.$store.state.resendBatch) {
				let noVats = []
				this.docsData.forEach(doc => {
					noVats.push(doc.noVat)
				})

				let uniqueNoVat = [...new Set(noVats)]
				this.ediCheck.noVAT = !(uniqueNoVat.length === 1 && uniqueNoVat[0] === false)
			} else if (this.$store.state.docType === 'BILLING') {
				if (this.$store.state.documentDetailData.billingData?.billingTo?.registration?.registrationNumber) {
					this.ediCheck.noVAT = false
				}
			}
		},
		displayTooltip(type, display) {
			if (type === 'edi' && this.isButtonDisabled.edi) this.displayTooltipEdi = display
			else if (type === "attachMail" && this.isButtonDisabled.attachMail) this.displayTooltipAttachMail = display
		},
		async displayDisable() {
			this.isButtonDisabled.edi = true
			this.showNoUbl = false
			this.showDifferentIdentifiers = false
			let ublAvailable = false
			this.docsData.forEach(doc => {
				if (doc.ublValid === undefined) ublAvailable = true
			})

			let firstIdentifiers = ''
			let sameIdentifiers = true

			if (this.docsData.length > 1 && !ublAvailable) {
				const res = await this.$store.dispatch('documents/getParticipantId', {docId: this.docsData[0].docId})
				firstIdentifiers = res.data.data.participantId.schemeId + ':' + res.data.data.participantId.receiverId

				for (const doc of this.docsData) {
					const res = await this.$store.dispatch('documents/getParticipantId', {docId: doc.docId})
					const peppolIdentifier = res.data.data.participantId.schemeId + ':' + res.data.data.participantId.receiverId
					if (firstIdentifiers !== peppolIdentifier) sameIdentifiers = false
				}
				if (!sameIdentifiers) this.showDifferentIdentifiers = true
			}
			this.isButtonDisabled.edi = false
			if (ublAvailable) {
				this.showNoUbl = true
				this.isButtonDisabled.edi = true
				return
			}
			if (this.docsData.length > 1 && !sameIdentifiers) this.isButtonDisabled.edi = true
			if (this.$store.state.documentFileSizes.pdfSize >= 10) {
				this.isButtonDisabled.attachMail = true
			}
		},
		displayButton(type) {
			let channelSetting = {
				attachEmail: true,
				linkEmail: true,
				print: true,
				edi: true
			}

			this.docsToResend.forEach(docToResend => {
				let currentLe
				if (docToResend.data === undefined) currentLe = docToResend.legalEntity.entityNumber
				else currentLe = docToResend.data.legalEntity.entityNumber
				const leSettings = this.$store.state.legalEntitiesData.data.legalEntities[currentLe].channelsConfig

				if (leSettings === undefined) {
					if (type === 'edi') {
						if (this.$store.state.docType !== "BILLING") channelSetting.edi = false
						else if (this.$store.state.supplierGroup.data.channelsConfig?.edi) channelSetting.edi = true
					}

					else if (type === 'attachMail' && this.$store.state.supplierGroup.data.channelsConfig) {
						channelSetting.attachEmail = this.$store.state.supplierGroup.data.channelsConfig.attachEmail
					}
					else if (type === 'linkMail' && this.$store.state.supplierGroup.data.channelsConfig) {
						channelSetting.linkEmail = this.$store.state.supplierGroup.data.channelsConfig.linkEmail
					}
					else if (type === 'print') {
						channelSetting.print = !!(this.$store.state.supplierGroup.data.channelsConfig?.print?.printJobs
							&& this.$store.state.supplierGroup.data.channelsConfig.print.printProvider === "SPEOS"
							&& !this.$store.state.resendBatch)
					}
				} else {
					if (!leSettings.attachEmail?.enabled) channelSetting.attachEmail = false
					if (!leSettings.linkEmail?.enabled ) channelSetting.linkEmail = false
					if (!leSettings.print?.enabled || this.$store.state.resendBatch) channelSetting.print = false
					if (!leSettings.edi?.enabled || this.$store.state.resendBatch) channelSetting.edi = false
				}
			})

			if (type === 'attachMail') return channelSetting.attachEmail
			else if (type === 'linkMail') return channelSetting.linkEmail
			else if (type === 'print') return channelSetting.print
			else if (type === 'edi') return channelSetting.edi

			return false
		},
		buttonTitle(type) {
			if (type === "linkMail") return i18n.t('buttons.resendLink')
			if (type === "print") return i18n.t('buttons.resendPrint')
			if (type === "edi") return i18n.t('buttons.resendEdi')
			return i18n.t('buttons.resendAttach')
		},

		makeActive(resendType) {
			for (let type in this.resendTypes) {
				this.resendTypes[type] = false;
			}
			this.resendTypes[resendType] = true;
			this.activeType = resendType
		}
	}
}
</script>

<style scoped>
.button-container-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-content: end;
}

.button-container-inner {
	display: grid;
	grid-template-columns: repeat(10, auto);
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-self: end;
}

@media only screen and (max-width: 680px) {
	.button-container-inner {
		grid-template-columns: repeat(2, auto);
		grid-template-rows: auto auto;
		grid-auto-flow: column;
		grid-row-gap: 4px;

	}
}

.button-container-inner button {
	margin-left: 10px;
}

.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.tooltip-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 24px;
	grid-auto-flow: row;
	justify-items: end;
}

.tooltip-container-attachMail {
	display: flex;
	justify-content: center;
}

.edi-tooltip {
	top: -5px;
	padding: 0 5px;
}

.attachMail-tooltip {
	top: -25px;
	justify-content: center;

}

.display-tooltip {
	display: block;
}

button:disabled:hover {
	display: block;
}

</style>
