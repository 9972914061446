<template>
	<div v-if="displayTable">
		<top-banner>
			<slot v-if="documentDataCheck">
				<h1>{{ header }} </h1>
			</slot>
		</top-banner>
		<document-status-viewer
			:show-in-pop-up="true"
			:doc-status-data="docStatusData"
			:show-statuses="showStatuses"
			@close-doc-statuses="showStatuses = false"
		/>

		<div class="main documentDetail intro-y ">
			<div class="box p-5">
				<action-buttons
					:access-code="accessCode"
					:doc-name="tableData.docName"
					:doc-number="tableData.docNumber"
					:legal-entity-id="tableData.legalEntityId"
					:doc-id="tableData.docId"
					:ubl-valid="tableData.showDownloadUBL"
					:visibility="tableData.visibility"
					:due-date="tableData.dueDate"
					:doc-type="tableData.docType"
					:ubl-file="tableData.ublFile"
					:errors-at-document="tableData.errorsAtDocument"
					:payment="tableData.payment"
					:channels="tableData.channels"
				/>
				<document-table
					:tableData="tableData"
					:access-code="accessCode"
				></document-table>
			</div>

			<div v-if="notPublic" class="showStatusesContainer mt-2 mb-2">
				<button class="btn custom-button shadow-md mr-2 showStatuses" @click="showDocInfo">
					<i class="icon-bell mr-3"></i>{{ $t('documentDetail.documentStatusesInfo.documentStatuses') }}
				</button>
			</div>
			<div class="document-container">
				<iframe class="pdfContainer"
						title="document"
						:src=documentPDFLink
						width="100%"
						height="100%"
				>
					<p>Your browser does not support PDFs.</p>
					<a target="_blank" :href=documentPDFLink>
						<button class="btn custom-button w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1">
							View document
							<p>description</p>
						</button>
					</a>
				</iframe>

				<div class="left-side-doc-Info">
					<document-status-viewer
						:show-in-pop-up="false"
						:doc-status-data="docStatusData"
						:show-statuses="showStatuses"
						@close-doc-statuses="showStatuses = false"
						:doc-id="tableData.docId"
						:legal-entity-id="tableData.legalEntityId"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {defineComponent} from "vue"
import TopBanner from "../TopBanner.vue"
import DocumentTable from "./Table.vue"
import i18n from "../../i18n"
import getDocType from "../../mixins/documentTable/calcDocType"
import getPaymentStatus from "../../mixins/documentTable/calcPayStatus"
import setEnvelopIcon from "../../mixins/documentTable/CalcEnvelopIcon"
import calcDueDate from "../../mixins/documentTable/calcDueDate"
import getAmount from "../../mixins/documentTable/calcAmount"
import getAttachments from "../../mixins/documentTable/calcAttachments"
import IsErrorsAtDocument from "../../mixins/documentTable/calcIfErrorsAtDocument"
import getPaymentButton from "../../mixins/documentTable/calcPaymentButton"
import moment from "moment/moment"
import userGroups from "../../userGoups"
import ActionButtons from "./actions/actionButtons.vue"
import DocumentStatusViewer from "./statuses/DocumentStatusViewer.vue"
import mergeAddress from "../../mixins/documentTable/MergeAddress";
import calcChannels from "../../mixins/documentTable/CalcChannels";
import calcCustomerPOReference from "../../mixins/documentTable/calcCustomerPOReference";

export default defineComponent({
	name: "DocumentDetailPage",
	components: {DocumentStatusViewer, ActionButtons, DocumentTable, TopBanner},
	data() {
		return {
			documentData: this.$store.state.documentDetailData,
			showStatuses: false,
			documentId: this.$route.params.docId,
			displayTable: false,
			docStatusData: {},
			displayPeppolResult: false,
			displayAskPopUp: false,
			styles: {
				isOnPeppol: false,
				isNotOnPeppol: false,
				peppolCheckError: false,
			},
			vatNumber: '',
			accessCode: this.$route.query.access_code ? this.$route.query.access_code : ''
		}
	},
	async mounted() {
		this.$store.commit("displayAskPopUp", {display: false, action: ''})
		if (this.notPublic) this.$store.dispatch("checkAcceptedTos", {requiresAuth: this.$route.meta.requiresAuth})
		this.$store.commit("setResendBatch", false);
		await this.getData()
		this.getDocumentStatusesData()
		if (!this.notPublic) this.$store.dispatch('documents/readPublicDocument', {
			user: 'Anonymous',
			documentId: this.documentData.docId
		});
		this.sendViewRequest()
		this.displayTable = true
	},
	watch: {
		"$store.state.documentDetailData": {
			handler() {
				this.documentData = this.$store.getters.getDocumentDetailData;
				this.getDocumentStatusesData()
			},
			deep: true
		},
	},
	computed: {
		userGroups() {
			return userGroups
		},
		notPublic() {
			return this.$route.name !== 'public'
		},
		documentDataCheck() {
			return this.documentData.docId !== undefined;
		},
		header() {
			let headerText = '';
			let docNumber = this.documentData.opTableInfo ? this.documentData.opTableInfo.document.documentNumber : this.tableData.docNumber
			if (this.$store.state.docType === "GENERAL") {
				headerText = i18n.t('documentDetail.header.general.title') + ' ' + this.tableData.licensePlate;
			}
			if (this.$store.state.docType === "BILLING") {
				headerText = i18n.t('documentDetail.header.billing.title') + ' ' + docNumber;
			}
			if (this.$store.state.docType === "SALARY") {
				headerText = i18n.t('documentDetail.header.salary.title') + ' ' + docNumber;
			}
			return headerText
		},
		displayCheckOnPeppol() {
			if (userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
				return false
			}
			if (this.$store.state.documentDetailData.fileStructure.ublFileName
				&& (this.$store.state.documentDetailData.billingData?.billingTo?.customerAddress?.countryCode
					&& this.$store.state.documentDetailData.billingData.billingTo.customerAddress.countryCode === "BE")
			) {
				if (this.$store.state.documentDetailData.billingData?.billingTo?.customerIdentification?.vatNumber) {
					this.setVatNumber(this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.vatNumber)
				} else if (this.$store.state.documentDetailData.billingData?.billingTo?.customerIdentification?.registration?.registrationNumber) {
					this.setVatNumber(this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.registration.registrationNumber)
				}
				return true
			}
			return false
		},
		documentPDFLink() {
			return 'https://' + this.documentData.fileStructure.bucketName + '.storage.googleapis.com/' + this.documentData.fileStructure.opDocumentPortalFileName
		},
		tableData() {
			let tableData = {}
			let type = getDocType(this.documentData, this.documentData.documentType)
			let docType = ''
			if (this.documentData.documentType.toLowerCase() === "invoice" ||
				this.documentData.documentType.toLowerCase() === "credit_note" ||
				this.documentData.documentType.toLowerCase() === "general") {
				docType = i18n.t('billing.' + type)
			} else if (this.documentData.documentType.toLowerCase() === 'salary') {
				docType = i18n.t('salary.' + type)
			}

			const payment = getPaymentStatus(this.documentData.channels.payment, calcDueDate(this.documentData, this.documentData.opTableInfo), type)

			let logoPath
			if (this.notPublic) {
				if (this.$store.state.legalEntitiesData.data.legalEntities[this.documentData.legalEntity.entityNumber]?.legalEntityConfig?.logo === undefined) logoPath = ''
				else logoPath = this.$store.state.legalEntitiesData.data.legalEntities[this.documentData.legalEntity.entityNumber].legalEntityConfig.logo
			} else {
				logoPath = this.$store.state.supplierGroup.data.supplierLegalEntities[this.documentData.legalEntity.entityNumber].legalEntityConfig ? this.$store.state.supplierGroup.data.supplierLegalEntities[this.documentData.legalEntity.entityNumber].legalEntityConfig.logo : ''
			}

			let receiverName = ''
			if (this.documentData.opTableInfo) {
				receiverName = this.documentData.opTableInfo.receiver.receiverName
			} else if (this.documentData.generalDocumentData) {
				receiverName = this.documentData.generalDocumentData.receiverName
			} else if (this.documentData.billingData?.billingTo) {
				receiverName = this.documentData.billingData.billingTo.customerIdentification.companyName
			} else if (this.documentData.salaryData) receiverName = this.documentData.salaryData.firstName + ' ' + this.documentData.salaryData.lastName

			if (this.$store.state.docType === "SALARY") {
				tableData.attachmentsFileNames = this.documentData.fileStructure.attachmentsFileNames
			}

			let docDate
			if (this.documentData.salaryData !== undefined) docDate = this.documentData.salaryData.documentDate
			else if (this.documentData.opTableInfo !== undefined) docDate = this.documentData.opTableInfo.documentDates.documentDate
			else if (this.documentData.generalDocumentData !== undefined) docDate = this.documentData.generalDocumentData.documentDate
			else docDate = this.documentData.billingData.documentDate

			const downloadName = this.documentData.fileStructure.opDocumentPortalFileName.split('/')

			let clientId = ''
			if (this.documentData.billingData?.clientId) {
				clientId = this.documentData.billingData.clientId
			} else if (this.documentData.opTableInfo) {
				clientId = this.documentData.opTableInfo.receiver.receiverId
			} else if (this.documentData.generalDocumentData) {
				clientId = this.documentData.generalDocumentData.receiverId
			} else if (this.documentData.salaryData) clientId = this.documentData.salaryData.employeeId

			let legalEntityName = this.documentData.legalEntity.name
			if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
				let le = this.$store.state.legalEntitiesData.data.legalEntities[this.documentData.legalEntity.entityNumber]
				if (le.internalName) legalEntityName = le.internalName
			}

			tableData = {
				viewPropertiesColumns: setEnvelopIcon(this.documentData),
				logo: logoPath ? logoPath : 'no-logo',
				legalEntityName: legalEntityName,
				docType: docType,
				customerName: receiverName,
				clientId: clientId,
				docNumber: this.documentData.documentNumber,
				docDate: docDate,
				visibility: this.documentData.visible ? `<i class="icon icon-eye1 icon-color visibility-doc-icon"></i>` : `<i class="icon icon-eye-blocked icon-color visibility-doc-icon"></i>`,
				dueDate: calcDueDate(this.documentData, this.documentData.opTableInfo),
				amount: getAmount(this.documentData, "totalAmountDue"),
				totalAmountTaxes: getAmount(this.documentData, "totalAmountTaxes"),
				totalTaxableAmount: getAmount(this.documentData, "totalTaxableAmount"),payment: payment.payment,
				paymentStatusTooltip: payment.paymentStatusTooltip,
				documentData: this.documentData.data,
				attachmentFileIcon: getAttachments(this.documentData.fileStructure.attachmentsFileNames, this.$store.state.docType),
				insertsFileNames: [],
				errorsAtDocument: IsErrorsAtDocument(this.documentData.channels),
				showDownloadUBL: this.documentData.fileStructure.ublFileName,
				downloadUBLTooltip: i18n.t('tooltips.downloadXml'),
				language: this.documentData.billingData?.language ? this.documentData.billingData.language : (this.documentData.generalDocumentData?.language ? this.documentData.generalDocumentData.language : this.documentData.salaryData.language) ,
				vatNrReceiver: this.documentData.billingData?.billingTo?.customerIdentification.vatNumber? this.documentData.billingData.billingTo.customerIdentification.vatNumber : '',
				vatNrSender: this.documentData.billingData?.billingFrom?.vatNumber ? this.documentData.billingData.billingFrom.vatNumber : '',
				customerPOReference: calcCustomerPOReference(this.documentData.billingOptionalData?.documentReferences),
				fullAddress: mergeAddress(this.documentData, type),
				channels: calcChannels(this.documentData.channels),

				startDate: this.documentData.generalDocumentData && this.documentData.generalDocumentData.startDate ? this.documentData.generalDocumentData.startDate : '',
				endDate: this.documentData.generalDocumentData && this.documentData.generalDocumentData.endDate ? this.documentData.generalDocumentData.endDate : '',
				licensePlate: this.documentData.carInsuranceData ? this.documentData.carInsuranceData.licensePlate : '',

				period: this.documentData.salaryData ? this.documentData.salaryData.period : '',
				employee: receiverName,

				// resend doc
				docId: this.documentData.docId,
				legalEntityId: this.documentData.opTableInfo ? this.documentData.opTableInfo.legalEntity.legalEntityId : this.documentData.legalEntity.entityNumber,

				//to download pdf with correct name
				docName: downloadName[downloadName.length - 1],
				// ubl
				ublFile: !!this.documentData.fileStructure.ublFileName,

				// receiverView
				paymentButton: getPaymentButton(this.documentData.channels.payment, this.documentData.documentType),
				paymentButtonTooltip: i18n.t('tooltips.payNow')
			}

			return tableData
		},

	},
	methods: {
		async getData() {
			if (this.documentData.docId === undefined) {
				if (this.notPublic) {
					let currentUserId = this.$store.state.currentUserData.data.id;

					await this.$store.dispatch('updateToken')
					await this.$store.dispatch('documents/getDocumentDetailData', {
						userId: currentUserId,
						documentId: this.documentId
					})
					await this.$store.dispatch('fetchLegalEntitiesData', {
						sgId: this.$store.state.supplierGroup.data.supplierGroupId
					})
					await this.$store.dispatch('fetchSupplierGroupBranding', {
						sgId: this.getBrandingOrSgId()
					})
					this.documentData = this.$store.getters.getDocumentDetailData;
				} else {
					await this.$store.dispatch('documents/fetchPublicDocumentData', {
						accessCode: this.$route.query.access_code,
						publicDocId: this.$route.params.docId
					});
					this.documentData = this.$store.state.publicDocumentInfo.data;
				}
			}
		},
		sendViewRequest() {
			if (this.$store.state.currentUserData.data.group === 'ReceiverUser' || this.$store.state.currentUserData.data.group === 'ReceiverAdmin') {
				this.$store.dispatch('documents/readDocument', {
					userEmail: this.$store.state.currentUserData.data.email,
					documentId: this.documentData.docId,
				})
			}
		},
		showDocInfo() {
			this.$store.commit('setPageYScrollPosition', window.pageYOffset)
			this.showStatuses = !this.showStatuses
		},
		getDocumentStatusesData() {
			//visibility + createdOn
			this.docStatusData.docStatuses = {
				visibility: this.documentData.visible,
				createdOn: moment(this.documentData.createdOn).format('DD/MM/YYYY'),
			};
			//show visibility
			this.docStatusData.docStatuses.showVisibility = !((this.$store.state.currentUserData.data.group === 'ReceiverUser') || (this.$store.state.currentUserData.data.group === 'ReceiverAdmin'));

			if ((this.documentData.visible)) {
				this.docStatusData.docStatuses.visibility = 'visible';
			} else this.docStatusData.docStatuses.visibility = 'notVisible';

			this.docStatusData.docType = getDocType(this.documentData, this.documentData.documentType)
			//signed
			if (this.documentData.channels?.archive?.signed !== undefined) this.docStatusData.signed = this.documentData.channels.archive.signed
			else {
				if (this.$store.state.supplierGroup.data.channelsConfig.signing?.docTypes) {
					this.docStatusData.signed = !!this.$store.state.supplierGroup.data.channelsConfig.signing.docTypes.find(type => type === this.documentData.documentType)
				} else this.docStatusData.signed = false
			}

			if (this.documentData.docId) {
				// HandledErrorInfo
				if (this.documentData.channels.archive && this.documentData.channels.archive.handledInfo) {
					this.docStatusData.handledInfo = {
						handledBy: 'unknown',
						handledAt: '-',
						reason: ''
					}
					if (this.documentData.channels.archive.handledInfo.handledBy) {
						this.docStatusData.handledInfo.handledBy = this.documentData.channels.archive.handledInfo.handledBy
					}
					if (this.documentData.channels.archive.handledInfo.handledAt) {
						this.docStatusData.handledInfo.handledAt = moment(this.documentData.channels.archive.handledInfo.handledAt).format('DD/MM/YYYY')
					}

					if (this.documentData.channels.archive.handledInfo.reason) {
						this.docStatusData.handledInfo.reason = this.documentData.channels.archive.handledInfo.reason
					}
				}
//attached  fileNames
				if (this.documentData.fileStructure.attachmentsFileNames) {
					let fileNames = []
					this.documentData.fileStructure.attachmentsFileNames.forEach(name => {
						let lastIndex = name.lastIndexOf("/");
						let displayedName = name.slice(lastIndex + 1);
						let tempItem = {
							fullName: name,
							displayedName: displayedName,
							href: 'https://' + this.documentData.fileStructure.bucketName + '.storage.googleapis.com/' + name,
						}
						fileNames.push(tempItem)
					})

					this.docStatusData.attachmentFileNames = fileNames
				}
				this.docStatusData.attachmentMerged = this.documentData.channels.archive.attachmentsMerged
//insertes fileName
				if (this.documentData.fileStructure.insertsFileNames || this.documentData.fileStructure.insertTemplateFileNames) {

					let insertsFileNames = []
					if (this.documentData.fileStructure.insertsFileNames) {
						this.documentData.fileStructure.insertsFileNames.forEach(name => {
							let lastIndex = name.lastIndexOf("/");
							let displayedName = name.slice(lastIndex + 1);
							let tempItem = {
								fullName: name,
								displayedName: displayedName,
								href: 'https://' + this.documentData.fileStructure.bucketName + '.storage.googleapis.com/' + name,
							}
							insertsFileNames.push(tempItem)

						})
					}
					if (this.documentData.fileStructure.insertTemplateFileNames) {
						this.documentData.fileStructure.insertTemplateFileNames.forEach(name => {
							let lastIndex = name.lastIndexOf("/");
							let displayedName = name.slice(lastIndex + 1);
							let tempItem = {
								fullName: name,
								displayedName: displayedName,
								href: 'https://' + this.documentData.fileStructure.bucketName + '.storage.googleapis.com/' + name,
							}
							insertsFileNames.push(tempItem)
						})
					}
					this.docStatusData.insertsFileNames = insertsFileNames
				}
// Payment info
				if (this.documentData.channels.payment?.paymentUpdates) {
					this.docStatusData.paymentUpdatesRaw = this.documentData.channels.payment.paymentUpdates;
					this.docStatusData.paymentInfo = this.documentData.channels.payment.paymentUpdates[this.documentData.channels.payment.paymentUpdates.length - 1]
				}
//Email with attachemt
				if (this.documentData.channels.attachEmail) {
					let emailRecipients = []; //all emails
					let inactiveEmail = [];   // inactive Emails
					let sentEmail = [];      // emails which has no errorOn, but not in feedback array
					let deliveredEmail = []; // emails from feedback.delivered
					let errorEmail = [];     // emails from feedback.bounces
					let openEmail = [];    //emails which are opened
					let spam = []          //emails from feedback.spamComplaint

					if (this.documentData.channels.attachEmail.emailRecipients) {
						this.documentData.channels.attachEmail.emailRecipients.forEach(item => {
							let tempRecipient = {
								email: item.email,
								procededOn: item.processedOn
							}
							emailRecipients.push(tempRecipient);

							if (item.error) {
								let tempItem = {
									email: item.email,
									errorOn: moment(item.errorOn).format("DD/MM/YYYY"),
									errorReason: item.errorReason,
									errorDescription: item.error
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.errorOn = moment(item.errorOn).format("DD/MM/YYYY h:mm:ss a")
								}

								inactiveEmail.push(tempItem);
							}
						})
					}

					if (this.documentData.channels.attachEmail.feedback) {
						if (this.documentData.channels.attachEmail.feedback.deliveries.length) {
							this.documentData.channels.attachEmail.feedback.deliveries.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									processedOn: moment(rec.deliveredAt).format("DD/MM/YYYY")
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.processedOn = moment(rec.deliveredAt).format("DD/MM/YYYY h:mm:ss a")
								}

								deliveredEmail.push(tempItem);
							})
						}

						if (this.documentData.channels.attachEmail.feedback.bounces.length) {
							this.documentData.channels.attachEmail.feedback.bounces.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									errorOn: moment(rec.bouncedAt).format("DD/MM/YYYY"),
									name: rec.name,
									description: rec.description
								};
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.errorOn = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								errorEmail.push(tempItem)
							})
						}

						if (this.documentData.channels.attachEmail.feedback.spamComplaints.length) {
							this.documentData.channels.attachEmail.feedback.spamComplaints.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.bouncedAt).format("DD/MM/YYYY"),
								};
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								spam.push(tempItem)
							})
						}
						if (this.documentData.channels.attachEmail.feedback.opens.length) {
							this.documentData.channels.attachEmail.feedback.opens.forEach((open) => {
								let tempItem = {
									email: open.recipient,
									time: moment(open.receivedAt).format("DD/MM/YYYY"),
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(open.receivedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								openEmail.push(tempItem)
							})
						}
					}

					let proceededEmailsInfo = [...deliveredEmail, ...errorEmail, ...inactiveEmail, ...spam]
					let proceededEmails = [];
					proceededEmailsInfo.forEach(item => proceededEmails.push(item.email))

					emailRecipients.forEach((rec) => {
						if (!(proceededEmails.includes(rec.email))) {
							let tempItem = {email: rec.email}

							if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
								|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
								tempItem.processedOn = moment(rec.procededOn).format("DD/MM/YYYY h:mm:ss a")
							} else tempItem.processedOn = moment(rec.procededOn).format("DD/MM/YYYY")
							sentEmail.push(tempItem)
						}
					})

					this.docStatusData.emailWithAttachment = {
						deliveredEmail: deliveredEmail,
						errorEmail: errorEmail,
						inactiveEmail: inactiveEmail,
						sentEmail: sentEmail,
						openEmail: openEmail,
						spam: spam
					}
				}
//email with link
				if (this.documentData.channels.linkEmail) {
					let emailRecipients = []; //all emails
					let inactiveEmail = [];   // emails with errorOn
					let sentEmail = [];      // emails which has no errorOn, but not in feedback array
					let deliveredEmail = []; // emails from feedback.delivered
					let errorEmail = [];     // emails from feedback.bounces
					let openEmail = [];    //emails which are opened
					let spam = []         //emails from feedback.spamComplaint

					if (this.documentData.channels.linkEmail.emailRecipients) {
						this.documentData.channels.linkEmail.emailRecipients.forEach(item => {
							let tempRecipient = {
								email: item.email,
								procededOn: moment(item.processedOn).format("DD/MM/YYYY")
							}

							if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
								|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
								tempRecipient.procededOn = moment(item.processedOn).format("DD/MM/YYYY h:mm:ss a")
							}

							emailRecipients.push(tempRecipient)

							if (item.error) {
								let tempItem = {
									email: item.email,
									errorOn: moment(item.errorOn).format("DD/MM/YYYY"),
									errorReason: item.errorReason
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.errorOn = moment(item.errorOn).format("DD/MM/YYYY h:mm:ss a")
								}

								inactiveEmail.push(tempItem)
							}
						})
					}

					if (this.documentData.channels.linkEmail.feedback) {
						if (this.documentData.channels.linkEmail.feedback.deliveries.length) {
							this.documentData.channels.linkEmail.feedback.deliveries.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									processedOn: moment(rec.deliveredAt).format("DD/MM/YYYY")
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.processedOn = moment(rec.deliveredAt).format("DD/MM/YYYY h:mm:ss a")
								}
								deliveredEmail.push(tempItem);
							})
						}

						if (this.documentData.channels.linkEmail.feedback.bounces.length) {
							this.documentData.channels.linkEmail.feedback.bounces.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									errorOn: moment(rec.bouncedAt).format("DD/MM/YYYY"),
									name: rec.name,
									description: rec.description
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.errorOn = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}

								errorEmail.push(tempItem)
							})
						}

						if (this.documentData.channels.linkEmail.feedback.spamComplaints.length) {
							this.documentData.channels.linkEmail.feedback.spamComplaints.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.bouncedAt).format("DD/MM/YYYY")
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								spam.push(tempItem)
							})
						}

						if (this.documentData.channels.linkEmail.feedback.opens.length) {
							this.documentData.channels.linkEmail.feedback.opens.forEach((open) => {
								let tempItem = {
									email: open.recipient,
									time: moment(open.receivedAt).format("DD/MM/YYYY"),
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(open.receivedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								openEmail.push(tempItem)
							})
						}
					}

					let proceededEmailsInfo = [...deliveredEmail, ...errorEmail, ...inactiveEmail, ...spam];
					let proceededEmails = []

					proceededEmailsInfo.forEach(item => {
						proceededEmails.push(item.email)
					})

					emailRecipients.forEach((rec) => {
						if (!(proceededEmails.includes(rec.email))) {
							let tempItem = {email: rec.email, processedOn: rec.procededOn};
							sentEmail.push(tempItem)
						}
					})

					this.docStatusData.linkEmail = {
						deliveredEmail: deliveredEmail,
						errorEmail: errorEmail,
						sentEmail: sentEmail,
						inactiveEmail: inactiveEmail,
						openEmail: openEmail,
						spam: spam
					}
				}
//viewed by
				if (this.documentData.viewProperties) {
					let receiver = this.documentData.viewProperties.receiver;

					this.docStatusData.viewedBy = {
						name: receiver,
						time: moment(this.documentData.viewProperties.timestamp).format('DD/MM/YYYY')
					}
				}
//edi
				if (this.documentData.channels.edi && this.documentData.channels.edi.updates) {
					this.docStatusData.ediProvider = this.documentData.channels.edi.network

					let updates = [];
					this.documentData.channels.edi.updates.forEach(update => {
						let tempItem = {
							time: moment(update.time).format("DD/MM/YYYY"),
							status: update.status
						}
						if (update.message) {
							tempItem.message = update.message
						}

						if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
							|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
							tempItem.time = moment(update.time).format("DD/MM/YYYY h:mm:ss a")
						}
						updates.push(tempItem)
					})
					this.docStatusData.edi = updates
				}
//print
				if (this.documentData.channels.print && this.documentData.channels.print.printStatuses) {
					let print = [];
					this.documentData.channels.print.printStatuses.forEach((printStatus) => {
						let tempItem = {
							status: printStatus.status.replaceAll('_', '').toLowerCase(),
							message: printStatus.message ? printStatus.message : '',
							time: moment(printStatus.time).format("DD/MM/YYYY")
						};
						print.push(tempItem)
					})
					this.docStatusData.print = print
				} else if (this.documentData.channels.print) {
					this.docStatusData.print = [{
						status: "Queued",
						message: "",
						time: moment(Date.now()).format("DD/MM/YYYY")
					}]
				}

				this.docStatusData.barCode = {
					code: '',
					registeredMail: false
				}

				if (this.documentData.channels && this.documentData.channels.print) {
					if (this.documentData.channels.print.registeredMail !== undefined) {
						this.docStatusData.barCode.registeredMail = this.documentData.channels.print.registeredMail
					}

					if (this.documentData.channels.print.printJob) {
						this.$store.state.supplierGroup.data.channelsConfig.print.printJobs.forEach(job => {
							if (job.name === this.documentData.channels.print.printJob && job.registeredMail !== undefined) {
								this.docStatusData.barCode.registeredMail = job.registeredMail
							}
						})
					}
					if (this.documentData.channels.print.additionalChannelInfo && this.documentData.channels.print.additionalChannelInfo.code) {
						this.docStatusData.barCode.code = this.documentData.channels.print.additionalChannelInfo.code;
					}
				}

				this.docStatusData.publicLink = '';

				let documentLanguage = this.$store.state.locale
				if (this.documentData.generalDocumentData?.language) documentLanguage = this.documentData.generalDocumentData.language.toLowerCase()
				else if (this.documentData.billingData?.language) documentLanguage = this.documentData.billingData.language.toLowerCase()
				else if (this.documentData.salaryData?.language) documentLanguage = this.documentData.salaryData.language.toLowerCase()

				this.docStatusData.publicLink = window.location.protocol + "//" + window.location.hostname + "/" + documentLanguage + "/documents/public/" + this.documentData.docId + "?access_code=" + this.documentData.channels.archive.accessCode;

				this.docStatusData.onboardingLink = '';
				if (this.documentData.channels.payment && this.documentData.channels.payment.mandateOnboardingLink) {
					let accessCode = this.documentData.channels.payment.mandateOnboardingLink;
					if (accessCode.includes('/')) {
						let foundIndex = accessCode.lastIndexOf('/');
						accessCode = accessCode.slice(foundIndex + 1);
					}

					this.docStatusData.onboardingLink = window.location.hostname + '/' + documentLanguage + '/onboarding/' + accessCode;
				}

				this.docStatusData.channelChoiceLink = '';
				if (this.documentData.channels.archive && this.documentData.channels.archive.channelChoiceLink) {
					let accessCode = this.documentData.channels.archive.channelChoiceLink;
					if (accessCode.includes('/')) {
						let foundIndex = accessCode.lastIndexOf('/');
						accessCode = accessCode.slice(foundIndex + 1);
					}

					let language = 'EN'
					if (this.$store.state.docType === "BILLING" && this.documentData.billingData?.language) language = this.documentData.billingData.language
					else if (this.$store.state.docType === "SALARY" && this.documentData.salaryData?.language) language = this.documentData.salaryData.language
					else if (this.$store.state.docType === "GENERAL" && this.documentData.generalDocumentData?.language) language =  this.documentData.generalDocumentData.language
					this.docStatusData.channelChoiceLink = window.location.hostname + '/' + language.toLowerCase() + '/channel-choice/' + accessCode;
				}

				if (this.documentData.mandateOnboardingData) {
					this.docStatusData.validityEnd = this.documentData.mandateOnboardingData.validityEnd
				}

				this.docStatusData.zipCode = '';
				if (this.documentData.channels.print?.postalAddress?.zip) {
					this.docStatusData.zipCode = this.documentData.channels.print.postalAddress.zip
				} else if (this.documentData.billingData?.billingFrom?.address?.zip) {
					this.docStatusData.zipCode = this.documentData.billingData.billingFrom.address.zip
				}
			}
		},
		setVatNumber(vat) {
			this.vatNumber = vat
		},
		getBrandingOrSgId() {
			if (this.$store.state.supplierGroup.data.brandingId) {
				return this.$store.state.supplierGroup.data.brandingId
			} else {
				return this.$store.state.supplierGroup.data.supplierGroupId
			}
		}
	}
})
</script>

<style scoped>
.main {
	min-height: calc(100vh - var(--topBannerHeight) - var(--footerHeight));
}

.document-container {
	margin-top: 5px;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 65% 35%;
	grid-auto-flow: column;
}

.pdfContainer {
	min-height: calc(100vh - var(--topBannerHeight) - var(--footerHeight));
	width: 100%;
}

.showStatuses {
	display: none;
}

.showStatusesContainer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-items: start;
}

.left-side-doc-Info .button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
	margin-bottom: 10px;
}


@media only screen and (max-width: 450px) {
	.showStatusesContainer {
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
		grid-row-gap: 10px;
	}
}

@media only screen and (max-width: 1000px) {
	.showStatuses {
		display: inline-flex;
	}

	.left-side-doc-Info {
		display: none;
	}

	.document-container {
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		grid-auto-flow: row;
	}
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
	.status-title h1 {
		font-size: 1.3rem;
	}
}


@media only screen and (max-width: 1450px) {
	.pdfContainer {
		padding-left: 0;
	}
}
</style>