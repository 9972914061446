<template>
	<transition name="dialog" v-if="this.$store.state.onboarding.channelChoice.display && dataIsReady">
		<page-container class="page-content onboarding channel-choice">
			<div class="width-100">
				<top-banner>
					<div class="w-9/12">
						<h1 class="text-3xl text-center">{{ $t('onboarding.channelChoice.title') }}</h1>
					</div>
				</top-banner>
				<div class="box page-content intro-y box p-5 mt-5">
					<div v-if="this.generatedBy === 'EMAIL' && !isOnPeppol">
						<h1>{{ $t('onboarding.channelChoice.notRegisteredOnPeppol') }}</h1>
					</div>
					<div v-else class="content-container">
						<h1 class=" mb-5 title">{{ $t(description).replace('XXX', receiverEmail.email) }}</h1>
						<div>
							<div class="flex flex-row justify-between mb-5">
								<h1 class="w-1/2">{{ $t('management.selectOnlyOneChannel') }}</h1>
								<vue-select
									class="w-1/2"
									style="margin-left: 18px"
									:disabled="!displaySaveButton"
									v-model="selectedChannelChoice"
									:options='channelChoices'
									@click="setAdditionalData('channelChoice')"
									@option:selected=setDisplayInfo()
									@option:deselected=setDisplayInfo()
								>
									<template #selected-option="option">
										{{ $t(option.label) !== '' ? $t(option.label) : option.label }}
									</template>
									<template #option="option">
										{{ $t(option.label) !== '' ? $t(option.label) : option.label }}
									</template>
									<template #no-options="{search}">
										{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
									</template>
								</vue-select>
							</div>
							<p v-if="errors.channelChoice" class="error">{{ $t('errors.channel') }}</p>
							<div v-if="displayReceiverEmails">
								<div class="email">
									<input
										type='text'
										:disabled="!displaySaveButton"
										v-model="receiverEmail.email"
										@click='setAdditionalData("emails")'
										class="form-control text-popup pl-3"
										:placeholder="$t('placeholders.email')"
										style="height: 36px"
									>
									<div>
										<vue-select
											v-model="receiverEmail.lang"
											:options='langOptions'
											:disabled="!displaySaveButton"
											@click='setAdditionalData("emails")'
										>
											<template #no-options="{search}">
												{{ $t('placeholders.noResultFound') }}
												<span class="info">{{ search }}</span>
											</template>
										</vue-select>
									</div>
								</div>
								<p v-if="errors.emails" class="error">{{ $t('errors.provideCorrectData') }}</p>
							</div>
						</div>
						<div v-if="choiceSaved" class="choice-saved">
							{{ $t('onboarding.channelChoice.choiceSaved') }}
						</div>
						<div class="grid grid-rows-1 justify-items-center my-5">
							<div>
								<button
									v-if="displaySaveButton"
									class="btn custom-button shadow-md mr-2 showStatuses text-popup"
									@click="saveChoice">
									{{ $t('buttons.save') }}
								</button>
							</div>
						</div>
						<div>
							<h2>
								{{ $t('onboarding.channelChoice.additonalInfo.contactUs') }}
								<strong><a
									:href=mailTo>{{
										this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.termsOfServiceEmail
									}}</a>
								</strong>
							</h2>
						</div>
						<div>
							<h2>
								{{ $t('onboarding.channelChoice.additonalInfo.readTermsAndConditions').split(' ').slice(0, -1).join(" ") }}
								<span @click='redirectToTaC' class="link">
									{{ $t('onboarding.channelChoice.additonalInfo.readTermsAndConditions').split(' ').pop() }}
								</span>
							</h2>
						</div>
						<img alt="peppol_logo" src="../assets/images/peppol_logo.png" class="w-1/3">
					</div>
				</div>
			</div>
		</page-container>

	</transition>

</template>

<script>
import TopBanner from "../components/TopBanner"
import PageContainer from "../components/PageContainer"
import routerData from "../routerData"
import router from "@/router"
import VueSelect from "vue3-select";

export default {
	name: "ChannelChoice",
	components: {VueSelect, TopBanner, PageContainer},
	data() {
		return {
			renderComponent: true,
			routerData: routerData,
			accessCode: '',
			userData: {},
			channelChoices: [],
			dataIsReady: false,
			formData: {
				onboardingAccessCode: '',
				supplierGroupId: '',
				legalEntityId: '',
				channelChoice: '',
				emails: {},
				ediInfo: {
					channelProvider: ''
				}
			},
			receiverEmail: {
				lang: '',
				email: ''
			},
			newReceiverEmails: [],
			selectedChannelChoice: '',
			companyIdentifierNumberType: '',
			companyIdentifierNumber: '',
			displayReceiverEmails: false,
			errors: {
				channelChoice: false,
				emails: false,
				atLeastOneEmail: false,
				onlyOneEmail: false,
			},
			langOptions: [],
			choiceSaved: false,
			selectedLang: '',
			description: '',
			generatedBy: '',
			isOnPeppol: false,
			choice: '',
			isJustSaved: false
		}
	},
	async created() {
		await this.getChannelChoiceData()
	},
	computed: {
		displaySaveButton() {
			if (this.selectedChannelChoice.value === "ATTACH_EMAIL" && this.isJustSaved){
				return false
			}
			if (this.$store.state.onboarding.channelChoice.data.channelInfo?.channelChoice && !this.isOnPeppol) {
				return false
			}
			return !(this.$store.state.onboarding.channelChoice.data.channelInfo?.channelChoice
				&& this.$store.state.onboarding.channelChoice.data.channelInfo.channelChoice[0] === "EDI")
		},
		mailTo() {
			return "mailto:" + this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.termsOfServiceEmail
		}
	},
	watch: {
		"$store.state.onboarding.channelChoice.data": {
			async handler(newVal) {
				this.userData = newVal;
				await this.setAllChannelChoices();
				this.formData.supplierGroupId = newVal.supplierGroupId;
				this.formData.legalEntityId = newVal.legalEntityId;
				this.formData.receiverId = newVal.receiverId;

				await this.registeredOnPeppol()

				if (newVal.channelChoiceCodes) {
					let generatedBy = '';
					newVal.channelChoiceCodes.forEach(code => {
						if (code.accessCode === this.formData.onboardingAccessCode.toUpperCase()) {
							generatedBy = code.generatedBy;
						}
					})
					this.generatedBy = generatedBy;
					if (generatedBy === "PRINT") {
						if (this.isOnPeppol) this.description = 'onboarding.channelChoice.description.generatedByPrint';
						else this.description = 'onboarding.channelChoice.description.generatedByPrintNotOnPeppol';
					}
					if (generatedBy === "EMAIL") {
						this.description = 'onboarding.channelChoice.description.generatedByEmail';
					}
				}

				if (newVal.channelInfo?.channelChoice) {

					newVal.channelInfo.channelChoice.forEach(choice => {
						if (choice === "EDI") {
							this.choice = "EDI"
							if (newVal.channelInfo.ediInfo) {
								this.formData.ediInfo = newVal.channelInfo.ediInfo
							}
							this.description = 'onboarding.channelChoice.description.selectedPeppol'
						}
						else if (choice === "ATTACH_EMAIL") {
							this.choice = 'ATTACH_EMAIL'
							if (this.isOnPeppol){
								this.description = 'onboarding.channelChoice.description.selectedEmailOnPeppol'
							} else this.description = 'onboarding.channelChoice.description.selectedEmailNotOnPeppol'
							if (newVal.channelInfo.emails) {
								for (let lang in newVal.channelInfo.emails) {
									this.receiverEmail.lang = {label: lang, value: lang};
									this.receiverEmail.email = newVal.channelInfo.emails[lang][0];
								}
							} else {
								this.formData.emails = {}
							}
							this.displayReceiverEmails = true;
						}
					})
					if (newVal.channelInfo.channelChoice.includes('ATTACH_EMAIL') && this.isOnPeppol && !this.isJustSaved) {
						this.selectedChannelChoice = {
							label: 'Peppol',
							value: 'Peppol'
						}
						let index = this.channelChoices.indexOf(this.channelChoices.find(choice => choice.value === "ATTACH_EMAIL"))
						this.channelChoices.splice(index, 1)
						this.displayReceiverEmails = false;
					} else if (newVal.channelInfo.channelChoice.includes('ATTACH_EMAIL')) {
						this.selectedChannelChoice = {
							label: 'management.channelChoice.attachemail',
							value: 'ATTACH_EMAIL'
						}
					}
					if (newVal.channelInfo.channelChoice.includes('EDI') && !(newVal.channelInfo.channelChoice.includes('ATTACH_EMAIL'))) {
						if (newVal.channelInfo.ediInfo) {
							if (newVal.channelInfo.ediInfo.channelProvider && newVal.channelInfo.ediInfo.channelProvider === "Peppol") {
								this.selectedChannelChoice = {
									label: newVal.channelInfo.ediInfo.channelProvider,
									value: newVal.channelInfo.ediInfo.channelProvider
								};
							} else {
								// this.selectedChannelChoice = {label: "Peppol", value: "Peppol"}   // JUST to display Now REMOVE IT when channelProvider is in responce!!!!!!!!!!!!
							}
						}
					}
				} else {
					this.channelChoices = []

					if (this.isOnPeppol) this.channelChoices.push({label: "Peppol", value: "Peppol"})

					if (this.generatedBy === "EMAIL") {
						this.selectedChannelChoice = {label: "Peppol", value: "Peppol"};
						this.displayReceiverEmails = false;
					}
					if (this.generatedBy === "PRINT") {
						this.channelChoices.push({
							label: 'management.channelChoice.attachemail',
							value: 'ATTACH_EMAIL'
						})
						if (this.isOnPeppol) {
							this.selectedChannelChoice = {label: "Peppol", value: "Peppol"}
							this.displayReceiverEmails = false
						} else {
							this.selectedChannelChoice = {
								label: 'management.channelChoice.attachemail',
								value: 'ATTACH_EMAIL'
							}
							this.displayReceiverEmails = true;
						}
					}
				}
			},
			deep: true
		}
	},

	methods: {
		setAdditionalData(field) {
			this.setErrors(field, false);
			this.setChoiceIsSaved(false);
		},
		async registeredOnPeppol() {
			let vat = ''
			if (this.$store.state.onboarding.channelChoice.data.info) {
				if (this.$store.state.onboarding.channelChoice.data?.info?.vatNumber) vat = this.$store.state.onboarding.channelChoice.data.info.vatNumber.substring(2)
				else vat = this.$store.state.onboarding.channelChoice.data.info.registration.registrationNumber
			}
			if (vat !== '') {
				let payload = {
					id: '0208:' + vat,
					documentType: 'BIS_BILLING_INVOICE_V3'
				}
				let status = await this.$store.dispatch('peppol/checkOnPeppolResend', payload)
				this.isOnPeppol = status === 200;
			}
		},
		setChoiceIsSaved(value) {
			this.choiceSaved = value
		},
		redirectToTaC() {
			router.push({path: routerData.tos.link})
		},
		setDisplayInfo() {
			let selectedChannels = this.selectedChannelChoice.value
			this.displayReceiverEmails = selectedChannels === "ATTACH_EMAIL"
		},
		saveChoice() {
			this.$store.commit("channelChoiceDataSaved", false)
			if (this.selectedChannelChoice === '') {
				this.setErrors('channelChoice', true)
				return
			} else {
				this.formData.channelChoice = [this.selectedChannelChoice.value]
			}

			if (this.formData.channelChoice.includes("Peppol")) {
				if (this.formData.ediInfo === undefined) {
					this.formData.ediInfo = {}
				}
				this.formData.ediInfo.channelProvider = "Peppol"
				this.formData.channelChoice[0] = "EDI"
			} else {
				delete this.formData.ediInfo
			}

			if (this.formData.channelChoice.includes("ATTACH_EMAIL")) {
				let emailsInvalid = false;
				const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
				let validEmail = re.test(this.receiverEmail.email);
				if (this.receiverEmail.email === '' || this.receiverEmail.email.language === '' || !validEmail) {
					emailsInvalid = true
				}
				if (emailsInvalid) {
					this.setErrors('emails', true)
					return
				}
				this.formData.emails[this.receiverEmail.lang.value] = [this.receiverEmail.email]
			} else {
				if (this.formData.emails) delete this.formData.emails
			}
			this.$store.dispatch('publicOnboardingChoice', this.formData)
			this.formData.emails = {}

			this.$store.state.confirmChangedDeliveryMethod = true
			this.isJustSaved = true
			this.$store.commit('displayNotification', true)

		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		async getChannelChoiceData() {
			this.setLocale()
			this.setPortalLangs()
			this.getAccessCode()
			await this.getReceiverData()
			await this.registeredOnPeppol()
		},
		setLocale() {
			let lang = window.location.href.split('/')[3]
			this.$store.commit('setLocale', lang)
			this.receiverEmail.lang = {value: lang.toUpperCase(), label: lang.toUpperCase()}
		},
		setPortalLangs() {
			let langOptions = []
			this.$store.state.supplierGroup.data.languages.forEach(lang => {
				langOptions.push({
					value: lang, label: lang,
				})
			})
			this.langOptions = langOptions
		},
		getAccessCode() {
			let accessCodeTemp = window.location.href.split('/')[5]
			let accessCode = accessCodeTemp.split('#')[0]
			this.formData.onboardingAccessCode = accessCode.trim()
		},
		async getReceiverData() {
			await this.$store.dispatch("users/getChannelChoiceData", {
				supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
				onboardingAccessCode: this.formData.onboardingAccessCode
			})
		},
		async setAllChannelChoices() {
			let options = []
			this.channelChoices = []
			let channelConfig = this.$store.state.supplierGroup.data.channelsConfig

			if (channelConfig.edi) {
				for (let ediProvider in channelConfig.edi.ediChannelProviders) {
					if (ediProvider === "Peppol") {
						await this.registeredOnPeppol()
						if (this.isOnPeppol) options.push({label: ediProvider, value: ediProvider})
					}
				}
			}
			if (channelConfig.attachEmail) {
				if (this.$store.state.onboarding.channelChoice.data.channelInfo === undefined) {
					if (this.$store.state.onboarding.channelChoice.data.channelChoiceCodes
						&& this.generatedBy !== "EMAIL") {
						options.push({label: 'management.channelChoice.attachemail', value: 'ATTACH_EMAIL'})
					}
				}
				if (this.$store.state.onboarding.channelChoice.data.channelInfo?.channelChoice
					&& this.$store.state.onboarding.channelChoice.data.channelInfo.channelChoice[0] !== "EDI"
					&& this.$store.state.onboarding.channelChoice.data.channelChoiceCodes
					&& this.generatedBy !== "EMAIL") {
					options.push({label: 'management.channelChoice.attachemail', value: 'ATTACH_EMAIL'})
				}
			}
			if (options.length === 1 && options[0].value === "ATTACH_EMAIL") {
				this.selectedChannelChoice = {
					label: 'management.channelChoice.attachemail',
					value: 'ATTACH_EMAIL'
				}
				this.displayReceiverEmails = true;
			}
			if (this.isOnPeppol && options.length < 2 && options[0].value === "Peppol") {
				this.selectedChannelChoice = {
					label: "Peppol",
					value: 'Peppol'
				}
			}
			this.channelChoices = options
			this.dataIsReady = true
		}
	}
}
</script>

<style scoped>
.page-content {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-items: center;
}

.content-container {
	width: 40%;
}

.vs__dropdown-menu {
	padding-left: 8px !important;
}

.width-100 {
	width: 100%;
}

i {
	color: var(--infoColor);
	font-size: 14px;
}

.one-item {
	display: grid;
	grid-template-columns: 52% 48%;
	grid-template-rows: 100%;
	align-items: center;
	margin-bottom: 20px;
}

.content-container h1 {
	font-weight: bolder;
	font-size: 14px;
	line-height: 1.5em;
}

.content-container h1.title {
	font-size: 18px;
	margin-top: 20px;
	font-weight: normal;
}

.choice-saved {
	text-align: center;
	margin-bottom: 10px;
	font-weight: bold;
	margin-top: 20px;
}

.email {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 50% 50%;
}

.email > div:first-child {
	margin-right: 10px;
}

.email > div:last-child {
	margin-left: 10px;
}

.email > div > input {
	padding: 0.65rem 0.75rem;
}

h2 {
	font-size: 16px;
	margin-bottom: 10px;
}

@media only screen  and (min-width: 1100px) and (max-width: 1500px) {
	.content-container {
		width: 60%;
	}
}

@media only screen  and (min-width: 800px) and (max-width: 1099px) {
	.content-container {
		width: 70%;
	}
}

@media only screen  and (min-width: 600px) and (max-width: 799px) {
	.content-container {
		width: 80%;
	}

	.content-container h1.title {
		font-size: 16px;
	}

	.content-container h2 {
		font-size: 14px;
	}
}

@media only screen  and (min-width: 400px) and (max-width: 599px) {
	.content-container {
		width: 90%;
	}

	.one-item {
		grid-template-columns: 30% 70%;
	}

	.content-container h1.title {
		font-size: 16px;
	}

	.content-container h2 {
		font-size: 14px;
	}
}

@media only screen  and (max-width: 399px) {
	.content-container {
		width: 95%;
	}

	.one-item {
		grid-template-columns: 30% 70%;
	}

	.content-container h1.title {
		font-size: 14px;
	}

	.content-container h2 {
		font-size: 12px;
	}

	.email {
		grid-template-rows: 50% 50%;
		grid-template-columns: 100%;
		grid-row-gap: 10px;
	}

	.email > div:first-child {
		margin-right: 0;
	}

	.email > div:last-child {
		margin-left: 0;
	}

	input {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
}
</style>