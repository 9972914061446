<template>
	<div class="mt-3.5">
		<!--client ID & document number-->
		<div class="one-line mb-2.5">
			<input
				type='text'
				v-model="extendedSearch.senderId"
				:placeholder="$t('placeholders.senderIdPeppol')"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				class="form-control"
			/>
			<input
				type='text'
				v-model="extendedSearch.senderName"
				:placeholder="$t('placeholders.senderName')"
				:class="showInOneColumn ? '' : 'ml-2.5'"
				class="form-control"
			/>
		</div>

		<div class="one-line mb-2.5">
			<input
				type='text'
				v-model="extendedSearch.receiverId"
				:placeholder="$t('placeholders.receiverIdPeppol')"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				class="form-control"
			/>
			<input
				type='text'
				v-model="extendedSearch.receiverName"
				:placeholder="$t('placeholders.receiverName')"
				:class="showInOneColumn ? '' : 'ml-2.5'"
				class="form-control"
			/>
		</div>
		<div class="one-line mb-2.5">
			<input
				type='text'
				v-model="extendedSearch.documentNumber"
				:placeholder="$t('placeholders.documentNumber')"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				class="form-control"
			/>
			<vue-select
				v-model="extendedSearch.direction"
				:options="directionOptions"
				:clearable="false"
				:class="showInOneColumn ? '' : 'ml-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) }}
				</template>
			</vue-select>
		</div>
		<div class="one-line mb-2.5" :style="showDocumentTypesReverse">
			<vue-select
				v-model="extendedSearch.peppolMessageType"
				:options="peppolMessageTypeOptions"
				:clearable="false"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:class="showInOneColumn ? '' : 'mr-2.5'"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) }}
				</template>
			</vue-select>
			<vue-select
				v-model="extendedSearch.retrieved"
				:options="peppolRetrievedViaApi"
				:clearable="false"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:class="showInOneColumn ? 'mb-2.5' : 'ml-2.5'"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) }}
				</template>
			</vue-select>
		</div>

		<div v-if="displayRejectedImrOnly" class="one-line mb-2.5">
			<div
				class="flex items-center"
				:class="showInOneColumn ? 'mb-2.5' : 'ml-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
			>
				<span>{{ $t('extendedSearch.rejectedImrOnly') }}</span>
				<input type="checkbox"
					   id="rejectedImrOnly"
					   class="customCheckbox ml-2"
					   :value="extendedSearch.rejectedImrOnly"
					   :checked="extendedSearch.rejectedImrOnly"
					   @change="setRejectedImrOnly()"
				/>
			</div>
		</div>
	</div>
</template>
<script>

import VueSelect from "vue3-select"

export default {
	components: {VueSelect},
	data() {
		return {
			showInOneColumn: window.innerWidth < 980,
			extendedSearch: {},
			messageTypes: [
				"Catalogue",
				"CatalogueResponse",
				"CreditNote",
				"DespatchAdvice",
				"Invoice",
				"InvoiceResponse",
				"MessageLevelResponse",
				"Order",
				"OrderAgreement",
				"OrderCancellation",
				"OrderChange",
				"OrderResponse",
				"OrderResponseAdvanced",
				"PunchOut",
				"Other"
			]
		}
	},
	created() {
		this.extendedSearch = {
			senderId: '',
			senderName: '',
			receiverId: '',
			receiverName: '',
			documentNumber: '',
			rejectedImrOnly: false,
			direction: this.directionOptions[0],
			peppolMessageType: this.peppolMessageTypeOptions[0]
		}
	},
	mounted() {
		// We are doing it like this, so it doesn't update the main class without needing to press search
		for (let filterElement in this.$store.state.extendedSearchValue.extendedSearch) {
			if (this.$store.state.extendedSearchValue.extendedSearch[filterElement] !== undefined) {
				this.extendedSearch[filterElement] = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch[filterElement]))
			}
		}
		if (this.extendedSearch.retrieved === undefined) this.extendedSearch.retrieved = this.peppolRetrievedViaApi[0]
		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
				if (!this.extendedSearch.direction) this.extendedSearch.direction = this.directionOptions[0]
				if (!this.extendedSearch.peppolMessageType) this.extendedSearch.peppolMessageType = this.peppolMessageTypeOptions[0]
				if (!this.extendedSearch.retrieved) this.extendedSearch.retrieved = this.peppolRetrievedViaApi[0]
			}
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		directionOptions() {
			return [
				{label: 'dropdowns.directions.allDirections', value: ''},
				{label: 'dropdowns.directions.incoming', value: 'INCOMING'},
				{label: 'dropdowns.directions.outgoing', value: 'OUTGOING'},
			]
		},
		showDocumentTypesReverse() {
			if (!this.showInOneColumn) {
				return ''
			}else return {flexDirection: 'column-reverse'}

		},
		peppolMessageTypeOptions() {
			let options = [{label: "dropdowns.peppolMessageTypes.AllTypes", value: ""}]
			this.messageTypes.forEach(item => {
				let key = "dropdowns.peppolMessageTypes." + item
				let tempItem;
				if (!(item === "CreditNote" || item === "Invoice")) {
					tempItem = {
						label: key, value: item
					}
				} else {
					if (item === "CreditNote") {
						tempItem = {
							label: key, value: "CREDIT_NOTE"
						}
					} else {
						tempItem = {
							label: key, value: item.toUpperCase()
						}
					}
				}
				options.push(tempItem)
			})
			return options
		},
		peppolRetrievedViaApi(){
			return [
				{label: 'dropdowns.retrieved', value: ''},
				{label: 'buttons.yes', value: true},
				{label: 'buttons.no', value: false}
			]
		},
		displayRejectedImrOnly() {
			let display

			if (this.extendedSearch.peppolMessageType.value === 'InvoiceResponse') {
				display = true
			} else {
				display = false
				this.setRejectedImrOnlyFalse()
			}
			return display
		}
	},
	methods: {
		setRejectedImrOnlyFalse() {
			this.extendedSearch.rejectedImrOnly = false
		},
		setRejectedImrOnly() {
			this.extendedSearch.rejectedImrOnly = !this.extendedSearch.rejectedImrOnly
		}
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>