<template>
	<div class="mt-3.5">
		<!--Legal entity & docType-->
		<div class="one-line w-full mb-2.5">
			<input
				class="form-control "
				:class="classesMandateId"
				style="	height: 37px;"
				v-model="extendedSearch.mandateId"
				:placeholder="$t('placeholders.mandateId')"
			/>
			<input
				class="form-control"
				:class="classesReceiverId"
				style="	height: 37px;"
				v-model="extendedSearch.receiverId"
				:placeholder="$t('placeholders.receiverId')"
			/>
		</div>
		<div class="one-line w-full">
			<vue-select
				v-model="extendedSearch.legalEntityIds"
				:options="legalEntitiesNames"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
				:multiple=true
				:placeholder="$t('general.allLegalEntity')"
			>
				<template #option="option">
					{{ option.value === null ? $t(option) : option.label }}
				</template>
				<template #selected-option="option">
					{{ option.value === null ? $t(option) : option.label }}
				</template>
			</vue-select>
			<vue-select
				v-model="extendedSearch.status"
				:options="['SIGNED', 'CANCELLED']"
				:class="showInOneColumn ? 'mb-2.5' : 'ml-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
				:placeholder="$t('placeholders.statuses')"
			>
				<template #option="option">
					{{ option.value === null ? $t(option.label) : option.label }}
				</template>
				<template #selected-option="option">
					{{ option.value === null ? $t(option.label) : option.label }}
				</template>
			</vue-select>
			</div>
		<div class="one-line w-full">
			<div class="flex flex-col w-full" :class="showInOneColumn ? '' : 'mr-2.5'">
				<p>{{$t('extendedSearch.directDebit.updatedAfter')}}</p>
				<vue-date-picker

					class="mb-2.5"
					v-model="extendedSearch.updatedAfter"
					auto-apply
					format="dd/MM/yyyy"
					:enableTimePicker=false
				>
					<template #input-icon>
						<span class="material-icons calendar-icon">calendar_month</span>
					</template>
					<template #clear-icon="{clear}">
						<span class="material-icons clear-icon" @click="clear">close</span>
					</template>
				</vue-date-picker>
			</div>
			<div
				class="ml-2.5 w-full"
				style=""
			/>
		</div>
	</div>
</template>
<script>

import userGroups from "../../../userGoups"
import VueSelect from "vue3-select"
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
	components: {VueDatePicker, VueSelect},
	data() {
		return {
			showInOneColumn: window.innerWidth < 980,
			extendedSearch: {},
			userIsReceiver: userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)
		}
	},
	created() {
		// this.extendedSearch = this.extendedSearchStartValue()
	},
	mounted() {
		// We are doing it like this, so it doesn't update the main class without needing to press search
		for (let filterElement in this.$store.state.extendedSearchValue.extendedSearch) {
			if (this.$store.state.extendedSearchValue.extendedSearch[filterElement] !== undefined) {
				this.extendedSearch[filterElement] = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch[filterElement]))
			}
		}
		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
				if (!this.extendedSearch.legalEntityIds) this.extendedSearch.legalEntityIds = []
				// else {
				// 	this.extendedSearch.legalEntityIds.forEach(leId => {
				// 		console.log(leId)
				// 		if (leId.value !== undefined ) leId = leId.value
				// 	})
				// }
			},
			deep: true
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		userGroups() {
			return userGroups
		},
		classesMandateId(){
			let classes = ''
			if(this.showInOneColumn) classes += 'mb-2.5 w-full'
			else classes += 'mr-2.5 w-1/2'

			return classes
		},
		classesReceiverId(){
			let classes = ''
			if(this.showInOneColumn) classes += 'w-full'
			else classes += 'ml-2.5 w-1/2'

			return classes
		},
		legalEntitiesNames() {
			let leNames = []
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities;

			for (const le in legalEntities) {
				let name = '';
				if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
					name = legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				} else {
					name = legalEntities[le].name
				}

				let tempItem = {
					label: name,
					value: legalEntities[le].entityNumber
				}
				leNames.push(tempItem)
			}

			if (leNames.length > 1) {
				leNames.sort(function (a, b) {
					const textA = a.label.toUpperCase()
					const textB = b.label.toUpperCase()
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
				})
			}

			return leNames
		}
	},
	methods: {

		extendedSearchStartValue() {
			return {
				legalEntityIds: []
			}
		}
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}

.calendar-icon {
	padding-top: 5px;
	font-size: 20px;
	padding-left: 6px;
	padding-right: 12px;
	color: #355983;
}

.clear-icon {
	position: absolute;
	font-size: 20px;
	right: 15px;
	color: #82858a;
	cursor: pointer;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>