<template>
	<transition name="dialog" v-if="displayComponent && this.$store.state.departmentsSet">
		<page-container class="page-content statuses-table">
			<pop-up-window
				:show="showStatuses"
				:showClose="false"
				:top20="true"
				fixed
			>
				<template #title>
					<h1 class="text-2xl"><i class="icon-bell"></i>
						{{ $t('documentDetail.documentStatusesInfo.documentStatuses') }}
					</h1>
				</template>
				<template #content>
					<processed-document-statuses :docStatuses=this.docData.statuses />
					<div class="button-container">
						<button class="btn custom-button shadow-md text-popup" @click="closeDocStatuses">
							{{ $t('buttons.close') }}
						</button>
					</div>
				</template>
			</pop-up-window>
			<top-banner>
				<div class="w-9/12">
					<h1 class="text-3xl text-center">{{ $t('postDrop.title') }}</h1>
				</div>
			</top-banner>
			<div class="box page-content intro-y box p-5 mt-5" v-if="checkResetPassword">
				<div class="box intro-y box p-5 mt-5">
					<processed-document-table :tableData="docData.table"/>
				</div>
				<div class="status-button-container">
					<button class="btn custom-button shadow-md statuses-button" @click="showDocStatuses">
						<i class="icon-bell mr-3"></i>{{ $t('documentDetail.documentStatusesInfo.documentStatuses') }}
					</button>
				</div>
				<div class="document-container mt-12">
					<iframe class="pdfContainer"
							:src=docData.docUrl
							width="100%"
							height="100%"
							title="Nymdrop document"
					>
						<p>Your browser does not support PDFs.</p>
						<a target="_blank" :href=docData.docUrl>
							<button class="btn custom-button w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1">
								View document
								<p>description</p>
							</button>
						</a>
					</iframe>
					<div class="left-side-doc-Info">
						<div class="button-container"></div>
						<processed-document-statuses :docStatuses=this.docData.statuses />
					</div>
				</div>
			</div>
		</page-container>
	</transition>
</template>
<script>
import TopBanner from "../components/TopBanner"
import PopUpWindow from "../components/UI/PopUpWindow"
import PageContainer from "../components/PageContainer"
import routerData from "../routerData"
import userGroups from "../userGoups"
import store from "../store"
import moment from "moment"
import ProcessedDocumentTable from "../components/processedDocument/ProcessedDocumentTable"
import ProcessedDocumentStatuses from "../components/processedDocument/ProcessedDocumentStatuses"
import i18n from "../i18n"
import printStatuses from "../locales/en.json"
import getStatusesHtml from "../mixins/nymdropTable/calcStatusesHtml"
import getStatusBar from "../mixins/nymdropTable/calcStatusBar"

const newLoginPage = window.newLoginPage
export default {
	name: "ProcessedDocument",
	components: {
		ProcessedDocumentTable,
		PageContainer, TopBanner,
		ProcessedDocumentStatuses,
		PopUpWindow
	},
	mounted() {
		this.$store.dispatch('checkAcceptedTos', {requiresAuth: this.$route.meta.requiresAuth})
		this.passwordReset()
		this.checkUser()
		this.checkDocId()
		this.setPrintStatusesList()
	},
	beforeUnmount() {
		this.$store.commit("setDocumentErrorHandled", false)
	},
	data() {
		return {
			showStatuses: false,
			displayComponent: false,
			docData: {},
			statusesList: [],
			printStatuses: printStatuses.dropdowns.printStatuses,
		}
	},
	watch: {
		locale() {
			this.setTranslations()
			let tooltipsData = [
				{cellName: "upload-files", translationField: 'uploadFiles'},
				{cellName: "user-can-not-upload-files", translationField: 'userCanNotUploadFiles'},
				{cellName: "queued", translationField: 'queued'},
				{cellName: "sent-to-printer", translationField: 'sentToPrinter'},
				{cellName: "printer-processing", translationField: 'printerProcessing'},
				{cellName: "processed-by-printer", translationField: 'processedByPrinter'},
				{cellName: "printed", translationField: 'printed'},
				{cellName: "printer-error", translationField: 'printerError'},
				{cellName: "reprocessedbyprinter", translationField: 'reprocessedByPrinter'},
				{cellName: "resenttoprinter", translationField: 'resentToPrinter'},
				{cellName: "requeued", translationField: 'requeued'},
				{cellName: "printer-returned", translationField: 'printerReturned'},
				{cellName: "printer-returned-handled", translationField: 'printerReturnedHandled'},
				{cellName: "printer-returned", translationField: 'printerReturned'},
			]
			tooltipsData.forEach(tooltip => {
				this.changeTooltipsTranslation(tooltip.cellName, tooltip.translationField)
			})
		},
		processedDocumentDocId(newVal, oldVal) {
			if (oldVal === undefined && newVal) {
				this.setDocData()
				this.setDisplayComponent(true)
			}
		},
		departmentCodeList() {
			this.setDocData()
		},
		errorHandled(newVal) {
			if (newVal) {
				let statusBar = document.querySelector('.error-at-document')
				if (statusBar) {
					statusBar.classList.remove('status-bar-error')
					statusBar.classList.add('status-bar-info')
					let errorHandledEl = document.getElementById("error-handled")
					errorHandledEl.classList.remove('display-none')
				}
			}
		}
	},
	computed: {
		displayHandleErrors() {
			if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| (userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) && this.$store.state.currentUserData.data.group !== 'SupplierLimitedSubUser')) {
				return !!(this.$store.state.processedDocumentData.documentData.processedDocumentHasError && !this.$store.state.processedDocumentData.documentData)
			}
			return false
		},
		errorHandled() {
			return this.$store.state.documentErrorHandled
		},
		locale() {
			return this.$store.state.locale
		},
		departmentCodeList() {
			return this.$store.state.userDepartmentCodeList
		},
		processedDocumentDocId() {
			return this.$store.state.processedDocumentData.docId
		},
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)))
		}
	},
	methods: {
		showDocStatuses() {
			this.showStatuses = true
		},
		closeDocStatuses() {
			this.showStatuses = false
		},
		handleErrors() {
			this.$store.commit("setDocumentErrorHandled", false)
			this.$store.dispatch("documents/handleErrors", {
				formData: {
					docIds: [this.$store.state.processedDocumentData.docId],
					postDropDocuments: true,
				},
				routeName: "processedDocument"
			})
		},
		changeTooltipsTranslation(cellName, cellTranslationField) {
			let cellClass = '.' + cellName
			let cells = document.querySelectorAll(cellClass)
			let key = 'tooltips.' + cellTranslationField
			if ((cells.length) !== 0) {
				cells.forEach((cell) => {
					cell.innerHTML = i18n.t(key)
				})
			}
		},
		setPrintStatusesList() {
			for (let item in this.printStatuses) {
				this.statusesList.push(item)
			}
		},
		setTranslations() {
			this.statusesList.forEach(item => {
				let cellClass = '.' + item
				let cells = document.querySelectorAll(cellClass)
				let key = 'dropdowns.printStatuses.' + item
				cells.forEach(cell => {
					cell.innerHTML = i18n.t(key)
				})
			})
		},
		setDocData() {
			/*Data for table start */
			if (this.$store.state.processedDocumentData.docId) {
				let rawDocData = this.$store.state.processedDocumentData
				this.docData.table = [{
					docId: rawDocData.docId,
					createdOn: moment(rawDocData.createdOn).format("DD/MM/YYYY"),
					department: rawDocData.department,
					dropDate: moment(rawDocData.dropDate).format("DD/MM/YYYY"),
					postDropName: '-',
					departmentIsDeletedClass: '',
					statuses: '',
					fileName: '',
					statusbar: '',
					batchId: '-',
				}]
				this.docData.statuses = {
					printStatuses: [],
					postalAddress: {}
				}
				if (rawDocData.print) {
					if (rawDocData.print.additionalChannelInfo && rawDocData.print.additionalChannelInfo.printMode) {
						this.docData.statuses.printMode = rawDocData.print.additionalChannelInfo.printMode
					} else {
						this.docData.statuses.printMode = "RECTO_VERSO"
					}
					if (rawDocData.print.prior) {
						this.docData.statuses.deliveryMethod = 'prior'
					}
					if (rawDocData.print.registeredMail) {
						if ((rawDocData.print.additionalChannelInfo && rawDocData.print.additionalChannelInfo.code)
							&& (rawDocData.print.postalAddress && rawDocData.print.postalAddress.zip)
						) {
							this.docData.statuses.bPostLink = "https://track.bpost.cloud/btr/web/#/search?lang=" + this.$store.state.locale + "&itemCode=" + rawDocData.print.additionalChannelInfo.code + "&postalCode=" + rawDocData.print.postalAddress.zip
						}
						if (!rawDocData.print.acknowledgementOfReceipt) {
							{
								this.docData.statuses.deliveryMethod = 'registered'
							}
						} else {
							this.docData.statuses.deliveryMethod = 'registeredack'
						}
					}
					if ((!rawDocData.print.prior && !rawDocData.print.acknowledgementOfReceipt && !rawDocData.print.registeredMail)) {
						this.docData.statuses.deliveryMethod = 'regular'
					}
				}
				let printStatuses = []

				this.docData.table[0].statusesHTML = getStatusesHtml(printStatuses, rawDocData.print)
				this.docData.table[0].statusBar = getStatusBar(printStatuses, rawDocData.documentData.errorsHandled)

				if (rawDocData.print && rawDocData.print.printStatuses) {

					rawDocData.print.printStatuses.forEach((printItem) => {
						if (printItem.status !== "PRINTER_PROCESSING") {
							let tempItem = {
								status: printItem.status.replaceAll('_', '').toLowerCase(),
								time: moment(printItem.time).format('DD/MM/YYYY'),
								message: printItem.message ? printItem.message : ''
							}
							this.docData.statuses.printStatuses.push(tempItem)
						}
					})
				}

				if (rawDocData.fileStructure) {
					let nameArray = rawDocData.fileStructure.opDocumentOriginalFileName.split('/')
					this.docData.table[0].fileName = nameArray[nameArray.length - 1]
				}
				let name = ''
				this.$store.state.userDepartmentCodeList.forEach(department => {
					if (department.value === rawDocData.documentData.department) {
						name = department.label
					}
				})
				if (rawDocData.documentData && rawDocData.documentData.batch) {
					this.docData.table[0].batchId = rawDocData.documentData.batch
				}
				if (name === '') {
					name = rawDocData.documentData.department + " (Department DELETED)"
					this.docData.table[0].departmentIsDeletedClass = 'deleted-department'
				}
				this.docData.table[0].department = name

				if (rawDocData.print?.postalAddress?.companyName) {
					this.docData.table[0].postDropName = rawDocData.print.postalAddress.companyName
					/*Data for Statuses info start*/
					this.docData.statuses.postalAddress = rawDocData.print.postalAddress
					/*Data for Statuses info end*/
				}
				/*Data for table end*/
				this.docData.docUrl = 'https://' + rawDocData.fileStructure.bucketName + '.storage.googleapis.com/' + rawDocData.fileStructure.opDocumentOriginalFileName
			}
		},
		setDisplayComponent(value) {
			this.displayComponent = value
		},
		checkDocId() {
			if (this.$store.state.processedDocumentData.docId) {
				this.setDocData()
				this.displayComponent = true
			} else {
				this.setDepartments()
				let url = window.location.href
				let urlArray = url.split('#')
				url = urlArray[0]
				urlArray = url.split('/')
				let docId = urlArray[urlArray.length - 1]
				this.$store.dispatch('documents/getProcessedDocumentData', docId)
			}
		},
		setDepartments() {
			if (!this.$store.state.departmentsSet) {
				let data = {
					pageNo: 1,
					pageSize: 100,
					supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
				}
				this.$store.dispatch('department/getDepartmentsData', data)
			}
		},
		checkUser() {
			if (this.$store.state.currentUserData.data.id) {
				if (!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.generalPost) {
					this.$router.push({path: routerData.notFound.link})
				}
				if (!((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
						|| (userGroups.optipostNames.includes(store.state.currentUserData.data.group)) || this.$store.state.currentUserData.data.departments)
				)) {
					this.$router.push({path: routerData.noAccess.link})
				}
			}
		},
		passwordReset() {
			if (newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
			) {
				this.$store.commit("setDisplayResetOwnPassword", true)
			}
		}
	}

}
</script>

<style scoped>
.document-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 65% 35%;
	grid-auto-flow: column;
}

.pdfContainer {
	min-height: calc(100vh - var(--topBannerHeight) - var(--footerHeight));
	width: 100%;
}

.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: column;
	justify-items: center;
	margin-bottom: 10px;
}

.statuses-button {
	display: none;
	margin-top: 20px;
	margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
	.statuses-button {
		display: block;
	}

	.left-side-doc-Info {
		display: none;
	}

	.document-container {
		grid-template-columns: 100%;
		margin-top: 0;
	}
}

.status-button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}
</style>