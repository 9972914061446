<template>
	<div>
		<button class="btn custom-button shadow-md help-btn" @click="openHelpPage = !openHelpPage">
			<span class="material-icons-outlined" style="font-size: 18px">help_outline</span>
		</button>
		<transition>
			<span v-if="openHelpPage" class="help-info">
			{{ $t('management.customizeTable.helpInfo') }}
		</span>
		</transition>

		<div class="flex flex-row justify-center w-full">
			<div id="unusedColumns" ref="table-unused-columns" style="width: 25rem; margin-right: 5%;"></div>
			<div style="width: 25rem">
				<div ref="table-used-columns"></div>
				<p class="mt-2" :class="totalWidth > 100 ? 'error': ''">
					{{ $t('management.customizeTable.totalWidth') }}: {{ totalWidth }}%</p>
			</div>
		</div>

		<div class="mt-2 overflow-auto" ref="table"></div>
		<div class="flex flex-row justify-center mt-2">
			<button class="btn custom-button shadow-md" style="margin-right: 10px" @click="saveCustomizeTable">
				{{ $t('buttons.save') }}
			</button>
			<button class="btn custom-button shadow-md" style="margin-right: 10px" @click="cancel">
				{{ $t('buttons.cancel') }}
			</button>
			<button
				class="btn custom-button shadow-md"
				@click="showReset = true"
				:disabled="this.$store.state.extraInfoCustomizedColumns?.preferenceId === undefined"
			>
				{{ $t('buttons.reset') }}
			</button>
		</div>
		<confirmation-with-props
			:description="$t('confirmation.description.showResetCustomizedTable')"
			:show-dialog="showReset"
			@cancel-action="showReset = false"
			@approve-action="resetTableView"
		/>
	</div>
</template>

<script>

import userGroups from "@/userGoups"
import en from "@/locales/en.json"
import nl from "@/locales/nl.json"
import de from "@/locales/de.json"
import fr from "@/locales/fr.json"
import sk from "@/locales/sk.json"
import es from "@/locales/es.json"
import getPaymentStatus from "@/mixins/documentTable/calcPayStatus"
import calcDueDate from "@/mixins/documentTable/calcDueDate"
import getDocType from "@/mixins/documentTable/calcDocType"
import setEnvelopIcon from "@/mixins/documentTable/CalcEnvelopIcon"
import getAmount from "@/mixins/documentTable/calcAmount"
import getAttachments from "@/mixins/documentTable/calcAttachments"
import getPaymentButton from "@/mixins/documentTable/calcPaymentButton"
import i18n from "@/i18n"
import {Tabulator} from "tabulator-tables"
import visibilityColumn from "@/mixins/tableColumns/visibilityColumn"
import selectColumn from "@/mixins/tableColumns/selectColumn"
import amountSalary from "@/mixins/tableColumns/amountColumnSalary"
import paymentStatusColumn from "@/mixins/tableColumns/paymentStatusColumn"
import ConfirmationWithProps from "@/components/UI/ConfirmationWithProps.vue";
import mergeAddress from "@/mixins/documentTable/MergeAddress";
import calcChannels from "@/mixins/documentTable/CalcChannels";
import calcCustomerPOReference from "@/mixins/documentTable/calcCustomerPOReference";

export default {
	components: {ConfirmationWithProps},
	emits: ["cancelChanges"],
	data() {
		return {
			tabulatorTestView: null,
			tabulatorUnusedColumns: null,
			tabulatorUsedColumns: null,
			tableData: [],
			columns: [],
			selectedDocuments: [],
			selectedDocsData: [],
			unusedColumns: [],
			usedColumns: [],
			totalWidth: 0,
			movedColumn: false,
			showReset: false,
			isResizeing: false,
			openHelpPage: false
		}
	},
	watch: {
		movedColumn() {
			if (this.movedColumn) {
				this.tabulatorUnusedColumns.replaceData(this.unusedColumns)
				this.tabulatorUsedColumns.replaceData(this.usedColumns)
				this.movedColumn = false
				this.setLocale()
			}
		},
		"$store.state.locale": {
			async handler() {
				this.setLocale()

				await this.usedColumns.forEach(col => {
					col.translation = this.translateRows(col.columnName)
				})
				this.tabulatorUsedColumns.redraw(true)

				await this.unusedColumns.forEach(col => {
					col.translation = this.translateRows(col.columnName)
				})
				this.tabulatorUnusedColumns.redraw(true)
			},
			// deep: true
		}
	},
	created() {
		// reset data so the manual changes (in overview and detail) for payment are reset
		this.$store.commit("setOverviewTableColumns", this.$store.state.docType)
		this.columns = []
		this.columns = this.$store.state.columnsOverview
	},
	async mounted() {
		await this.$store.dispatch('updateToken')
		await this.setLoadedData()
	},

	methods: {
		async setLoadedData() {
			this.setCustomizedTable()

			this.tableColumns()
			this.setColumnsUsedAndUnused()

			await this.initTabulatorUnusedColumns()
			await this.initTabulatorUsedColumns()

			this.totalWidth = 0
			this.usedColumns.forEach(column => {
				this.totalWidth += column.width
			})

			// When no settings were made and payment is not enabled, you mis 8%, so we add 2% to the 4 last columns
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus === undefined || this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus === false) {
				let index = this.columns.indexOf(this.columns.find(col => col.title === 'PAYMENT'))
				if (index > 0) this.columns.splice(index, 1)
			}
			if (this.$store.state.customizedColumns.length === 0 && this.totalWidth <= 92) {
				let extraWidthColumns = this.columns.splice(Math.max(this.columns.length - 4))
				extraWidthColumns.forEach(col => {
					col.width = Number(col.width.toString().replace('%', '')) + 2 + '%'
					this.columns.push(col)

					let indexCol = this.columns.findIndex(column => column.field === col.field)
					let indexUsedCol = this.usedColumns.findIndex(column => {
						return column.columnName === col.field
					})
					this.usedColumns[indexUsedCol].width = Number(this.columns[indexCol].width.replace('%', ''))

				})

			} else if (this.$store.state.customizedColumns.length === 0 && this.totalWidth <= 98) {
				this.columns.find(col => col.field === 'visibility').width = '7%'
				this.usedColumns.find(col => col.columnName === 'visibility').width = 7
			}
			this.totalWidth = 0
			this.usedColumns.forEach(column => {
				this.totalWidth += Number(column.width)
			})
			await this.initTabulatorTestView()

			this.setEventsTable()

			window.addEventListener('resize', () => {
				if (this.isResizeing === false && this.tabulatorTestView !== undefined) {
					this.isResizeing = true
					this.tabulatorTestView.setColumns(this.columns)
					this.isResizeing = false
				}
			})
			this.$store.state.firstRenderOverviewPage = false
		},
		setColumnsUsedAndUnused() {
			this.$store.state.AllNamesColumnsOverviewBilling.forEach(name => {
				if (name === "payment" && (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus === undefined || this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus === false)) {
					return
				} else if (this.columns.find(column => column?.field === name)) {
					const column = this.columns.find(column => column?.field === name)
					this.usedColumns.push({
						columnName: name,
						translation: this.translateRows(name),
						width: Number(column.width.replace('%', ''))
					})

				} else if (name === 'envelopIcon') {
					if (this.columns.find(column => column.field === 'viewProperties')) {
						const column = this.columns.find(column => column.field === 'viewProperties')
						this.usedColumns.push({
							columnName: name,
							translation: this.translateRows(name),
							width: Number(column.width.replace('%', ''))
						})
					}
				} else if (name === 'selectDoc') {
					const column = this.columns.find(column => column.field === 'docId')
					this.usedColumns.push({
						columnName: name,
						translation: this.translateRows(name),
						width: Number(column.width.replace('%', ''))
					})
				} else {
					let column = this.$store.state.columnsOverviewBilling[name]
					if (column === undefined) column = this.$store.state.extraBillingColumns[name + 'Column']
					if (name === "payment" && this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus === false) column = undefined

					if (column) this.unusedColumns.push({
						columnName: name,
						translation: this.translateRows(name),
						width: Number(column.width.replace('%', ''))
					})
				}
			})

			this.usedColumns.sort((roleA, roleB) => {
				if (roleA.translation.toLowerCase() < roleB.translation.toLowerCase()) {
					return -1
				}
				if (roleA.translation.toLowerCase() > roleB.translation.toLowerCase()) {
					return 1
				}
				return 0
			})

			this.unusedColumns.sort((roleA, roleB) => {
				if (roleA.translation.toLowerCase() < roleB.translation.toLowerCase()) {
					return -1
				}
				if (roleA.translation.toLowerCase() > roleB.translation.toLowerCase()) {
					return 1
				}
				return 0
			})

			const rowPayment = this.usedColumns.find(row => row.columnName === "payment")
			if (rowPayment !== undefined) {
				const indexPayment = this.usedColumns.indexOf(rowPayment)
				this.usedColumns.splice(indexPayment, 1)
				this.usedColumns.unshift(rowPayment)
			}

			const rowID = this.usedColumns.find(row => row.columnName === "docId")
			const index = this.usedColumns.indexOf(rowID)

			this.usedColumns.splice(index, 1)
			this.usedColumns.unshift(rowID)

			this.usedColumns.unshift({
				columnName: 'borderSpace',
				translation: this.translateRows('borderSpace'),
				width: 2
			})
		},
		translateRows(value) {
			let newCellText = ''
			let key = 'columns.' + value


			if (i18n.t(key).trim() === '') {
				newCellText = i18n.t('management.customizeTable.overview.' + value)
			} else newCellText = i18n.t(key)
			return newCellText
		},
		initTabulatorUnusedColumns() {
			this.tabulatorUnusedColumns = new Tabulator(this.$refs["table-unused-columns"], {
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",

				rowHeight: 25,
				layout: "fitColumns",
				columnDefaults: {
					resizable: false,
				},
				movableRowsConnectedTables: this.$refs["table-used-columns"],
				autoResize: true,
				// movableRowsReceiver: "add",
				movableRowsReceiver: (fromRow) => {
					if (fromRow._row.data.columnName !== 'docId' && fromRow._row.data.columnName !== "borderSpace" && fromRow._row.data.columnName !== "payment") {
						this.unusedColumns.push(fromRow._row.data)
						const index = this.usedColumns.findIndex(row => row.columnName === fromRow._row.data.columnName)
						this.usedColumns.splice(index, 1)
						this.movedColumn = true

						return true
					} else {
						this.movedColumn = true
						return false
					}
				},
				locale: true,
				movableRowsSender: (fromRow) => {
					this.usedColumns.push(fromRow._row.data)
					const index = this.unusedColumns.findIndex(row => row.columnName === fromRow._row.data.columnName)
					this.unusedColumns.splice(index, 1)
					this.movedColumn = true
					return true
				},
				movableRows: true,
				resizableColumnFit: true,
				placeholder: "All Rows Moved",
				data: this.unusedColumns,
				langs: {
					"en-us": en,
					"nl-nl": nl,
					"de-de": de,
					"fr-fr": fr,
					"sk-sk": sk,
					"es-es": es,
				},
				columns: [
					{
						title: "columnNameUnused",
						field: "columnName",
						formatter(cell) {
							return `<div  class="columnName" data-columnName="${cell.getData().columnName}">
                                        ${cell.getData().translation}
                                </div>`
						}
					},
					{title: "width", field: "width", editor: "input", width: '30%'}
				],
			})
		},
		initTabulatorUsedColumns() {
			this.tabulatorUsedColumns = new Tabulator(this.$refs["table-used-columns"], {
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				layout: "fitColumns",
				rowHeight: 24,

				movableRowsConnectedTables: this.$refs["table-unused-columns"],
				movableRowsReceiver: "add",
				movableRowSender: "delete",
				columnDefaults: {
					resizable: false,
				},
				placeholder: "Drag Rows Here",
				movableRows: true,
				data: this.usedColumns,
				locale: true,
				resizableColumnFit: true,
				rowFormatter: function (row) {
					if (row._row.data.columnName === 'docId' || row._row.data.columnName === "borderSpace" || row._row.data.columnName === "payment") {
						row.getElement().style.backgroundColor = "#EBEBE4"
						row.getElement().style.color = "#919191"
					}
				},
				langs: {
					"en-us": en,
					"nl-nl": nl,
					"de-de": de,
					"fr-fr": fr,
					"sk-sk": sk,
					"es-es": es,
				},
				columns: [
					{
						title: "ColumnNameUsed",
						field: "columnName",
						width: '70%',
						formatter(cell) {
							return `<div  class="columnName" data-columnName="${cell.getData().columnName}">
                                        ${cell.getData().translation}
                                </div>`
						}
					},
					{title: "width", field: "width", editor: "input", width: '30%',}
				],
			})
		},
		initTabulatorTestView() {
			const store = this.$store

			this.tabulatorTestView = new Tabulator(this.$refs.table,
				{
					ajaxURL: this.$store.state.domainName + this.$store.state.API.search,
					movableColumns: true,
					ajaxConfig: {
						method: "POST",
						headers: {
							'Authorization': 'bearer ' + store.state.token,
							'Accept': 'application/json',
						},
					},
					ajaxParams: {
						extended: {},
						filters: {
							clientIds: null,
							legalEntityIds: null,
							published: true,
							visible: null,
							held: false
						},
						legalEntityNumber: null,
						pageNo: 1,
						pageSize: 3,
						quick: store.state.extendedSearch.overview.quickSearch,
						sortOn: null,
						supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
						general: false,
						salary: false,
						billing: true,
					},
					ajaxResponse: function (url, params, response) {
						let data = []
						const res = response.data.items

						for (let item of res) {
							// for the resend button
							const payment = getPaymentStatus(item.data.channels.payment, calcDueDate(item.data, item.opTableInfo), getDocType(item.data, item.docType))

							let logoPath = store.state.legalEntitiesData.data.legalEntities[item.data.legalEntity.entityNumber]?.legalEntityConfig
							let tempItem = {
								viewPropertiesColumns: setEnvelopIcon(item.data),
								logo: logoPath?.logo ? logoPath.logo : 'no-logo',
								legalEntityName: item.data.legalEntity.name,
								docType: getDocType(item.data, item.docType),
								customerName: item.opTableInfo.receiver.receiverName,
								clientId: item.data.billingData && item.data.billingData.clientId ? item.data.billingData.clientId : item.opTableInfo.receiver.receiverId,
								docNumber: item.data.documentNumber,
								visibility: item.data.visible ? `<i class="icon icon-eye1 icon-color visibility-doc-icon"></i>` : `<i class="icon icon-eye-blocked icon-color visibility-doc-icon"></i>`,
								docDate: item.data.salaryData ? item.data.salaryData.documentDate : item.opTableInfo.documentDates.documentDate,
								dueDate: calcDueDate(item.data, item.opTableInfo),
								amount: getAmount(item.data, "totalAmountDue"),
								totalAmountTaxes: getAmount(item.data, "totalAmountTaxes"),
								totalTaxableAmount: getAmount(item.data, "totalTaxableAmount"),
								payment: payment.payment,
								paymentStatusTooltip: payment.paymentStatusTooltip,
								documentData: item,
								attachmentFileIcon: getAttachments(item.data.fileStructure.attachmentsFileNames, store.state.docType),
								showDownloadUBL: item.data.fileStructure.ublFileName,
								downloadUBLTooltip: i18n.t('tooltips.downloadXml'),
								language: item.data.billingData?.language ? item.data.billingData.language : item.data.generalDocumentData.language,
								vatNrReceiver: item.data.billingData?.billingTo?.customerIdentification.vatNumber ? item.data.billingData.billingTo.customerIdentification.vatNumber : '',
								vatNrSender: item.data.billingData?.billingFrom?.vatNumber ? item.data.billingData.billingFrom.vatNumber : '',
								customerPOReference: calcCustomerPOReference(item.data.billingOptionalData?.documentReferences),
								fullAddress: mergeAddress(item.data, getDocType(item.data, item.docType)),
								channels: calcChannels(item.data.channels),

								startDate: item.data.generalDocumentData && item.data.generalDocumentData.startDate ? item.data.generalDocumentData.startDate : '',
								endDate: item.data.generalDocumentData && item.data.generalDocumentData.endDate ? item.data.generalDocumentData.endDate : '',
								licensePlate: item.data.carInsuranceData ? item.data.carInsuranceData.licensePlate : '',

								// salary
								period: item.data.salaryData ? item.data.salaryData.period : '',
								employee: item.opTableInfo.receiver && item.opTableInfo.receiver.receiverName ? item.opTableInfo.receiver.receiverName : '',

								// resend doc
								docId: item.data.docId,
								legalEntityId: item.opTableInfo ? item.opTableInfo.legalEntity.legalEntityId : item.data.legalEntity.entityNumber,

								// peppol
								noVat: true,

								// receiverView
								paymentButton: getPaymentButton(item.data.channels.payment, item.data.documentType),
								paymentButtonTooltip: i18n.t('tooltips.payNow'),
							}
							data.push(tempItem)
						}
						return data
					},
					dataSendParams: {
						page: "pageNo",
						filter: "quick"
					},
					ajaxURLGenerator: function (url, config, params) {
						store.commit("setOverviewPageNumber", params.pageNo)
						return url
					},
					dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
					headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
					ajaxContentType: "json",
					rowHeight: 44.9,
					resizableColumnFit: true,
					columnDefaults: {
						resizable: false,
					},
					ajaxFiltering: true,
					printAsHtml: true,
					printStyled: true,
					layout: 'fitColumns',
					locale: true,
					langs: {
						"en-us": en,
						"nl-nl": nl,
						"de-de": de,
						"fr-fr": fr,
						"sk-sk": sk,
						"es-es": es,
					},
					columns: this.columns,
				})
		},
		setLocale() {
			this.setColumnsTranslation()

			if (this.$store.getters.getLocale === 'en') {
				this.tabulatorTestView.setLocale(this.$store.getters.getLocale + '-us')
				this.tabulatorUsedColumns.setLocale(this.$store.getters.getLocale + '-us')
				this.tabulatorUnusedColumns.setLocale(this.$store.getters.getLocale + '-us')
			} else {
				this.tabulatorTestView.setLocale(this.$store.getters.getLocale + '-' + this.$store.getters.getLocale)
				this.tabulatorUsedColumns.setLocale(this.$store.getters.getLocale + '-' + this.$store.getters.getLocale)
				this.tabulatorUnusedColumns.setLocale(this.$store.getters.getLocale + '-' + this.$store.getters.getLocale)
			}

			this.tabulatorUnusedColumns.getColumn('columnName').updateDefinition({ title: i18n.t('columns.columnNameUnused') });
			this.tabulatorUsedColumns.getColumn('columnName').updateDefinition({ title: i18n.t('columns.columnNameUsed') });
		},
		setColumnsTranslation() {
			if (this.$store.state.docType === "BILLING") {
				if ((document.querySelectorAll('.billing-doc-type').length) === 0) {
					setTimeout(this.setColumnsTranslation, 100)
					return
				}
				if ((document.querySelectorAll('.billing-doc-type').length) !== 0) {
					let docTypeCells = document.querySelectorAll('.billing-doc-type')
					docTypeCells.forEach(cell => {
						let newCellText = ''
						let dataDoctype = cell.getAttribute('data-doctype')
						let key = 'billing.' + dataDoctype
						newCellText = i18n.t(key)
						cell.innerHTML = newCellText
					})
				}
			}
			if (this.$store.state.docType === "SALARY") {
				if ((document.querySelectorAll('.salary-doc-type').length) === 0) {
					setTimeout(this.setColumnsTranslation, 100)
					return
				}
				if ((document.querySelectorAll('.salary-doc-type').length) !== 0) {
					let docTypeCells = document.querySelectorAll('.salary-doc-type')
					docTypeCells.forEach(cell => {
						let newCellText = ''
						let salaryType = cell.getAttribute('data-doctype')
						let key = 'salary.' + salaryType

						newCellText = i18n.t(key)
						cell.innerHTML = newCellText
					})
				}
			}

			let columnNames = document.querySelectorAll('.columnName')
			columnNames.forEach(cell => {
				let newCellText = ''
				let dataDoctype = cell.getAttribute('data-columnName')
				let key = 'columns.' + dataDoctype

				newCellText = i18n.t(key)
				if (newCellText.trim() === '') {
					newCellText = i18n.t('management.customizeTable.overview.' + dataDoctype)
				}
				cell.innerHTML = newCellText
			})
		},
		setCustomizedTable() {
			// set customerTable back to original size
			if (this.$store.state.customizedColumns.length === 0) {
				let customerCol = this.$store.state.customizedColumns.find(col => col.columnId === "customerName")
				if (customerCol !== undefined) {
					this.columns.find(col => col?.field === "customerName").width = customerCol.width + '%'
				} else this.columns.find(col => col.field === "customerName").width = '20%'
			}
		},
		setEventsTable() {
			this.tabulatorTestView.on('dataLoaded', () => {
				this.setLocale()
				this.tabulatorTestView.getColumns()[0].getElement().style.backgroundColor = "#EBEBE4"
			})
			this.tabulatorTestView.on('columnMoved', (column) => {
				if (column._column.field === "docId" && this.tabulatorTestView.getColumns()[0]._column.field !== "docId") {
					column.move(this.tabulatorTestView.getColumns()[0]._column.field, false)
				}
			})

			this.tabulatorUsedColumns.on('movableRowsReceived', (row) => {
				if (this.$store.state.columnsOverviewBilling[row._row.cells[0].value] !== undefined) {
					this.tabulatorTestView.addColumn(this.$store.state.columnsOverviewBilling[row._row.cells[0].value])
				} else {
					if (row._row.cells[0].value === 'docId') this.tabulatorTestView.addColumn(selectColumn(this.selectedDocuments, this.selectedDocsData))
					else if (row._row.cells[0].value === 'visibility') this.tabulatorTestView.addColumn(visibilityColumn())
					else if (row._row.cells[0].value === 'amount') this.tabulatorTestView.addColumn(amountSalary())
					else if (row._row.cells[0].value === 'payment') this.tabulatorTestView.addColumn(paymentStatusColumn())
					else if (row._row.cells[0].value === "viewProperties") this.tabulatorTestView.addColumn(this.$store.state.columnsOverviewBilling["viewProperties"])
					else {
						this.tabulatorTestView.addColumn(this.$store.state.extraBillingColumns[row._row.cells[0].value + 'Column'])
					}

				}
				this.tabulatorTestView.getColumns().forEach(column => {
					if (column._column.definition.field !== "docId") column.updateDefinition({movable: true})
				})

				if (typeof row._row.data.width === 'string') this.totalWidth += Number(row._row.data.width.replace('%', ''))
				else this.totalWidth += Number(row._row.data.width)
				this.tabulatorTestView.redraw(true)
			})

			this.tabulatorUsedColumns.on('movableRowsSent', (row) => {
				if (row._row.data.columnName === "envelopIcon") {
					this.tabulatorTestView.deleteColumn('viewProperties')
				} else {
					this.tabulatorTestView.deleteColumn(row._row.data.columnName)
				}
				this.totalWidth -= Number(row._row.data.width)

				this.setLocale()
				this.tabulatorTestView.redraw(true)
			})
			this.tabulatorUsedColumns.on("dataChanged", (data) => {
				if (data.length === this.tabulatorTestView.getColumns().length + 1) {
					data.forEach(async newValue => {
						if (newValue.columnName === 'docId') {
							const row = this.tabulatorUsedColumns.getRows().find(row => row._row.data.columnName === 'docId')
							if (row._row.data.width !== 2) {
								row.update({width: 2})
							}
						} else if (newValue.columnName === 'borderSpace') {
							const row = this.tabulatorUsedColumns.getRows().find(row => {
								return row._row.data.columnName === 'borderSpace'
							})
							if (this.usedColumns.find(row => row.columnName === "borderSpace").width !== 2) {
								row.update({width: 2})

							}
						} else {
							let column = this.tabulatorTestView.getColumns().find(column => {
								if (newValue.columnName === 'envelopIcon') {
									return column._column.field.toString() === "viewProperties"
								}
								return column._column.field === newValue.columnName
							})
							if (column !== undefined) {
								if (Number(column._column.definition.width.replace('%', '')) !== newValue.width) {
									this.tabulatorTestView.updateColumnDefinition(column._column.definition.field, {width: newValue.width + '%'})
									this.tabulatorTestView.redraw()
								}
							}
						}
					})
					this.totalWidth = 0
					this.tabulatorUsedColumns.getRows().forEach(row => {
						this.totalWidth += Number(row._row.data.width)
					})
				}

			})
		},
		tableColumns() {
			let firstColumn = selectColumn()
			let firstColumnItem = Object.values(Object.assign({}, this.columns[0]))

			if ((firstColumnItem[0] !== firstColumn.title)) {
				this.columns.unshift(firstColumn)
			} else {
				this.columns.splice(0, 1)
				this.columns.unshift(firstColumn)
			}
			if (this.$store.state.docType === "BILLING") {
				// visibility
				if ((this.$store.state.customizedColumns.length === 0 || this.$store.state.customizedColumns.find(col => col.columnId === "visibility"))
					&& (userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) || userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group))
					&& this.columns.find(col => col?.title === 'VISIBILITY') === undefined) {
					if (this.$store.state.overviewTable.billing.displayVisibilityColumn) {
						if (this.$store.state.customizedColumns.length === 0) {
							this.columns.splice(this.columns.length - 3, 0, visibilityColumn())
						} else {
							const visibilityCol = this.$store.state.customizedColumns.find(col => col.columnId === "visibility")
							this.columns.splice(visibilityCol.index, 0, visibilityColumn())
						}
					}
				}
			}

			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus && this.columns.find(pay => pay?.title === "PAYMENT") === undefined) {
				this.columns.splice(this.columns.length - 1, 0, paymentStatusColumn())
			}
			if (this.$store.state.docType === "SALARY") {
				if (this.columns.find(col => col.title === "AMOUNT") === undefined && this.$route.name === 'public'
					|| (this.$route.name === 'overview' && !(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)))) {
					if (this.$store.state.overviewTable.salary.displayAmountColumn) {
						this.columns.splice(this.columns.length - 1, 0, amountSalary())
					}
				}
			}
		},
		async saveCustomizeTable() {
			let columns = []
			let origColumns = this.tabulatorTestView.getColumns()
			for (let i = 0; i < origColumns.length; i++) {
				let width = this.tabulatorUsedColumns.getRows().find(row => {
					return row._row.data.columnName === origColumns[i]._column.definition.field
				})
				let origCol = this.$store.state.customizedColumns.find(col => col.columnId === origColumns[i]._column.definition.field)

				if (width !== undefined) {
					let newCol = {
						tableId: "overview",
						columnId: origColumns[i]._column.definition.field,
						index: i,
						width: Number(width._row.data.width),
						visible: true
					}
					if (origCol !== undefined) {
						newCol.elementId = origCol.elementId
						newCol.preferenceId = origCol.preferenceId
					} else if (this.$store.state.extraInfoCustomizedColumns?.preferenceId) {
						newCol.preferenceId = this.$store.state.extraInfoCustomizedColumns.preferenceId
					} else newCol.preferenceId = "customOverviewTable"
					columns.push(newCol)
				}
			}

			let status = 0
			if (this.$store.state.customizedColumns.length === 0) {
				let payload = {
					preferenceElements: columns
				}
				status = await this.$store.dispatch('createCustomizedTableSupplierGroup', payload)
			} else {
				let payload = this.$store.state.extraInfoCustomizedColumns
				payload.preferenceElements = columns
				status = await this.$store.dispatch('updateCustomizedTableSupplierGroup', payload)
			}

			if (status === 200) {
				this.$store.state.savedCustomizedTable = true
				this.$store.commit('displayNotification', true)
			}

			await this.$store.dispatch('getCustomizedTableSupplierGroup', this.$store.state.supplierGroup.data.supplierGroupId)
			this.$store.commit("setOverviewTableColumns", this.$store.state.docType)
			this.columns = []
			this.columns = this.$store.state.columnsOverview

		},
		async cancel() {
			this.$emit("cancelChanges")
		},
		async resetTableView() {
			this.showReset = false
			await this.$store.dispatch('deleteCustomizedTableSupplierGroup')
			await this.$store.dispatch('getCustomizedTableSupplierGroup', this.$store.state.supplierGroup.data.supplierGroupId)
			this.$emit("cancelChanges")
		}
	}
}
</script>

<style>
@import "../../../assets/sass/projectStyles/customizeTableStyle.scss";

.help-btn {
	position: absolute;
	right: 20px;
	margin-right: 10px;
	border-radius: 50% !important;
	width: 30px;
	height: 30px;
}

.help-info {
	position: absolute;
	background-color: #e3e4e6;
	right: 30px;
	margin-top: 32px;
	width: 17%;
	min-height: 15%;
	font-size: 16px;
	border-radius: 3px;
	z-index: 2;
	padding: 5px;
	box-shadow: 5px 5px 10px 2px #c7c8c9;
}
/* animation (css is used) */
.v-enter-active,
.v-leave-active {
	transition: opacity 0.4s ease
}

.v-enter-from,
.v-leave-to {
	opacity: 0
}
</style>
