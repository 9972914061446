import axios from "axios"
import router from "@/router"
import moment from 'moment'
import routerData from "../routerData"
import userGroups from "@/userGoups";
import store from "@/store/index";

axios.interceptors.request.use(async (config) => {
	if (!config.url.includes("protocol/openid-connect/token")
		&& !config.url.includes("/public")
		&& !config.url.includes("/user/Anonymous")
		&& !config.url.includes("/current")
		&& !config.url.includes("/channel-choice/")
		&& !document.documentURI.includes("/public/")
		&& !config.url.includes("/proxy-login")
		&& !config.url.includes("/exchange-token/")
		&& !config.url.includes("/api/branding/theme/")
		&& !config.url.includes("/peppol/lookup")
		&& !config.url.includes("/onboarding/")) {

		await store.dispatch("updateToken")
		config.headers.Authorization = "bearer " + store.state.token
		if (store.state.tokenIsValid === true) return config
	} else return config

})

const actions = {
	async fetchSupplierGroupData(context) {
		let url = context.state.domainName + context.state.API.curentSG
		return await axios.get(url)
			.then(async (response) => {
				await context.dispatch('getCustomizedTableSupplierGroup', response.data.data.supplierGroupId)
				context.commit('setOverviewTableColumns', response.data.data.sgType)
				context.commit('setDocDetailColumns', response.data.data.sgType)

				context.commit('setDocType', response.data.data.sgType)
				context.commit('setSupplierGroupData', response.data.data)
			})
			.catch((err) => console.error(err))
	},
	fetchSupplierGroupBranding(context, payload) {
		let url = context.state.domainName + context.state.API.branding + payload.sgId

		try {
			if (payload.sgId === undefined) {
				throw new Error("Something went wrong. The Suppliergroup id is undefined. Please try again")
			}

			return axios.get(url).then((response) => {
				context.commit('setSupplierGroupBranding', response.data.data)
			})
		} catch (err) {
			console.error("fetchSupplierGroupBranding: " + err)
		}
	},
	async getCustomizedTableSupplierGroup(context, payload) {
		let url = context.state.domainName + context.state.API.getPreferencesTable.replace(':supplierGroupId', payload)
		context.state.customizedColumns = null
		if (context.rootState.userIsAuthenticated){
			await axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.state.token,
				}
			}).then(response => {
				if (response.data.data[0] !== undefined){
					context.state.customizedColumns = response.data.data[0].preferenceElements

					context.state.extraInfoCustomizedColumns = {
						preferenceId: response.data.data[0].preferenceId,
						preferenceLevel: response.data.data[0].preferenceLevel,
						preferenceType: response.data.data[0].preferenceType,
						supplierGroupId: response.data.data[0].supplierGroupId
					}
				}
				else context.state.customizedColumns = []
			}).catch(err => {
				context.state.customizedColumns = []
				console.error(err)
			})
		} else {
			context.state.customizedColumns = []
		}
	},
	async createCustomizedTableSupplierGroup(context, payload) {
		const url = context.state.domainName + context.state.API.createPreferencesTable

		const resp = await axios.post(url, payload,{
			headers: {
				'Authorization': "bearer " + context.state.token,
			}
		}).catch(err => {
			console.error(err)
			context.state.errorNotification.display = true
		})
		return resp.status
	},
	async updateCustomizedTableSupplierGroup(context, payload) {
		const url = context.state.domainName + context.state.API.updatePreferencesTable.replace(':preferenceId', payload.preferenceId)

		const resp = await axios.put(url, payload,{
			headers: {
				'Authorization': "bearer " + context.state.token,
			}
		}).catch(err => {
			console.error(err)
			context.state.errorNotification.display = true
		})
		return resp.status
	},
	async deleteCustomizedTableSupplierGroup(context){
		let url = context.state.domainName + context.state.API.deletePreferencesTable.replace(':preferenceId', context.state.extraInfoCustomizedColumns.preferenceId)

		await axios.delete(url).then(resp => {
			context.state.customizedColumns = []
			context.state.extraInfoCustomizedColumns = null
			console.log(resp)
		}).catch(err => {
			console.error(err)
			context.state.errorNotification.display = true
		})
	},
	fetchLegalEntitiesData(context) {
		let url = context.state.domainName + context.state.API.legalEntitiesData
		return axios.get(url,
			{
				headers: {
					'Authorization': "bearer " + context.state.token,
				}
			}).then((res) => {
			context.commit('setLegalEntitiesData', res.data.data)
		}).catch((err) => console.error(err))
	},

	retrieveSuppressionEmails(context, payload) {
		let url = context.state.domainName + context.state.API.retrieveSuppressions

		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.state.token,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		).then((res) => {
			let emailSuppressions = []
			res.data.data.suppressions.forEach(suppression => {
				let tempItem = {
					email: suppression.email,
					reason: suppression.reason,
					createdAt: moment(suppression.createdAt).format('YYYY-MM-DD'),
					origin: suppression.origin
				}
				if (suppression.reason === "SpamComplaint") {
					tempItem.reasonToDisplay = "Spam complaint"
				}
				if (suppression.reason === "HardBounce") {
					tempItem.reasonToDisplay = "Hard bounce"
				}
				if (suppression.reason === "ManualSuppression") {
					tempItem.reasonToDisplay = "Manual suppression"
				}
				emailSuppressions.push(tempItem)
			})

			emailSuppressions.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
			context.commit('setEmailSuppressions', emailSuppressions)
			context.commit('setEmailSuppressionsStatus', true)
		})
			.catch(() => {
				context.state.errorNotification.display = true
			})
	},
	checkAcceptedTos(context, payload) {
		if (context.state.currentUserData.data.id && context.state.docType === "BILLING" && payload.requiresAuth) {
			if (userGroups.receiverNames.includes(context.state.currentUserData.data.group)) {
				if (!context.state.currentUserData.data.termsAndConditionsAcceptedOn) {
					router.push({path: routerData.tos.link})
				}
			}
		}
	},
	downloadLeTos(context, payload) {
		let url = context.state.domainName + context.state.API.leTerms
		return axios.post(url, {
				index: payload.index,
				lang: payload.lang,
				legalEntityNumber: payload.legalEntityNumber
			},
			{
				responseType: 'blob',
				headers: {
					'Authorization': "bearer " + context.state.token,
				}
			}
		).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'TermsAndConditions.pdf') //or any other extension
			document.body.appendChild(link)
			link.click()
		}).catch(() => context.state.errorNotification.display = true)
	},

	publicOnboardingChoice(context, payload) {
		let url = context.state.domainName + context.state.API.publicOnboardingChoice
		return axios.post(url, payload, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		).then((res) => {
			context.commit("channelChoiceDataSaved", true)
			context.commit("setChannelChoiceData", res.data.data.items[0])
		}).catch(() => context.state.errorNotification.display = true)
	},
	checkChannelChoiceData(context, payload) {
		let url = context.state.domainName + context.state.API.channelChoice

		return axios.post(url, payload)
			.then((res) => {
				if (res.data.data.numResults > 0) context.commit('accessCodeReplyLength', 1)
				else context.commit('accessCodeReplyLength', 0)
			})
			.catch((err) => console.error(err))
	},

	checkGuide(context, payload) {
		let url = context.state.domainName + context.state.API.downloadGuide + payload

		return axios.get(url)
			.then(() => context.commit("manualExist", true))
			.catch(() => context.commit("manualExist", false))
	},
	downloadGuide(context, payload) {
		let url = context.state.domainName + context.state.API.downloadGuide + payload

		return axios.get(url)
			.then(res => window.open(res.data.data, '_newtab'))
			.catch(() => router.push('/notFound'))
	}
}
export default actions