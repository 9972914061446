import store from "../../store";

export default function getPaymentStatus(payment, dueDate, doctype) {
	let paymentStatus = {
		payment: '',
		paymentStatusTooltip: ''
	}
	let today = new Date()
	const todayMS = today.getTime()
	let open = false
	let paid = false
	let paidTran = false
	let confirmed = false
	let pending = false
	let error = false
	let paymentStatuses = []

	if (doctype.toLowerCase() !== "creditnote" && doctype.toLowerCase() !== "invoice"){
		return paymentStatus
	}

	if (doctype.toLowerCase() === "creditnote") {
		if (payment?.channelState?.state) {
			if (payment.channelState.state === "PROCESSED") {
				paymentStatus.payment = '<span class="payment-icon payment-icon-green"></span>'
				paymentStatus.paymentStatusTooltip = "processed"
				return paymentStatus
			}
		}
		return paymentStatus
	}

	if (payment?.paymentUpdates && payment.paymentUpdates.length > 0) {
		paymentStatuses.push(payment.paymentUpdates[payment.paymentUpdates.length - 1].paymentStatus)

		if ((paymentStatuses.includes('PAID') || paymentStatuses.includes('PAID_ONLINE') || paymentStatuses.includes('PAID_TRAN')) && !(paymentStatuses.includes('PAYMENT_CONFIRMED'))) {
			paid = true
			if (paymentStatuses.includes('PAID_TRAN')){
				paidTran = true
			}
		}
		else {
			if (paymentStatuses.includes('PAYMENT_CONFIRMED') && !paymentStatuses.includes('ERROR_TRAN')) {
				confirmed = true
			} else {
				if ((paymentStatuses.includes('PENDING_TRAN') || paymentStatuses.includes('PENDING') || paymentStatuses.includes('ERROR_TEMP_TRAN')) && !(paymentStatuses.includes('ERROR_TRAN'))) {
					paymentStatus.paymentStatusTooltip = "pending"
					if (paymentStatuses.includes('ERROR_TEMP_TRAN')) {
						paymentStatus.paymentStatusTooltip = "pendingTempTran"
					}
					pending = true
				} else {
					if (paymentStatuses.includes('ERROR_TRAN')) error = true
					else open = true
				}

			}
		}

		if (paid) {
			paymentStatus.payment = '<span class="payment-icon payment-icon-blue"></span>'
			if (paidTran){
				paymentStatus.paymentStatusTooltip = "paidTran"
			} else {
				paymentStatus.paymentStatusTooltip = "paidOnline"
			}
		} else if (confirmed) {
			paymentStatus.payment = '<span class="payment-icon payment-icon-green"></span>'
			paymentStatus.paymentStatusTooltip = "confirmed"
		} else if (pending) {
			paymentStatus.payment = '<span class="payment-icon payment-icon-yellow"></span>'
			paymentStatus.paymentStatusTooltip = "pending"
		} else if (error) {
			paymentStatus.payment = '<span class="payment-icon payment-icon-red"></span>'
			paymentStatus.paymentStatusTooltip = "error"
		} else if (open) {
			if (new Date(dueDate).getTime() >= todayMS) {
				paymentStatus.payment = '<span class="payment-icon payment-icon-gray"></span>'
				paymentStatus.paymentStatusTooltip = "open"
			} else {
				paymentStatus.payment = '<span class="payment-icon payment-icon-red"></span>'
				paymentStatus.paymentStatusTooltip = "overdue"
			}
		}
	} else if (payment === undefined && store.state.docDetailTable.billing.displayPaymentColumn === true) {
		if (new Date(dueDate).getTime() >= todayMS) {
			paymentStatus.payment = '<span class="payment-icon payment-icon-gray"></span>'
			paymentStatus.paymentStatusTooltip = "open"
		} else {
			paymentStatus.payment = '<span class="payment-icon payment-icon-red"></span>'
			paymentStatus.paymentStatusTooltip = "overdue"
		}
	}
	return paymentStatus
}
